import React from "react";

import style from './checkbox.module.scss'

const Checkbox = ({onChange, checked,size,...props}) => {
    return(
        <div className={style.checkbox} style={{width:size,
            height:size,
            minWidth: size,
            borderRadius: 0.25*size
        }}
             onClick={onChange}
        >
            {checked && (
                <svg width={(12/20)*size} height={(9/20)*size} viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.5 3.8L4.63043 7L10.5 1" stroke="#282E3A" strokeWidth="2"/>
                </svg>
            )}
        </div>
    )
}

export default Checkbox;