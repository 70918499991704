import React, {useEffect, useState} from 'react';
import { useIdleTimer } from 'react-idle-timer';
import Header from "../../Header/Header";
import {Route, Switch, useHistory } from 'react-router-dom';
import FirstTask from "./FirstTask/FirstTask";
import SecondTask from "./SecondTask/SecondTask";
import ThirdTask from "./ThirdTask/ThirdTask";
import {sendRequest} from "../../../helper";
import {
    BACK_API,
    GET_COUNT_TEST, GET_FAREWALL, GET_USER_DATA,
    GET_USER_IMAGE,
    HOST,
    TEST_ATTENTIVENESS, TEST_RESULT,
    TEST_SINCERITY,
    TEST_STATUS
} from "../../../helper/configurationConstants";
import {useDispatch, useSelector} from "react-redux";
import {setCountTasks, setFarewell} from "../../../store/actions/userActions";
import { IDLE_MODAL, INFO_MODAL } from '../../Modals/modalHelper';


const Tasks = ({setModal,logo,setLogo,...props}) => {

    const access = useSelector((state) => state.access.access)
    const [countTests, setCountTests] = useState(3);
    const dispatch = useDispatch();
    const [results, setResults] = useState();
    const [uuid, setUuid] = useState();
    const [tasks, setTasks] = useState(1);

    const history = useHistory();

    const handleOnIdle = (event) => {
        setModal({
            visible: true,
            modal_type: INFO_MODAL,
            type: IDLE_MODAL
        });
    }

    

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 5,
        // onIdle: handleOnIdle,
        onIdle: handleOnIdle,
        debounce: 750
    })

    // useEffect(()=>{
    //     if (countTests === 2 && window.location.pathname.includes('first')){
    //         loadData();
    //     }
    // },[window.location.pathname])

    const loadData = () => {
        Promise.all([
            sendRequest('GET', GET_USER_DATA,undefined,access, setModal).then(res => {
                if (res && !res.statusCode){
                    setUuid(res.uuid)
                    localStorage.setItem('uuid', res.uuid)
                    sendRequest('POST', GET_USER_IMAGE, JSON.stringify({userId: res.uuid}), access).then(result => {
                        if (result && result.path)
                            setLogo(HOST + 'static/' + result.path);
                    })
                    return res.uuid;
                } else {
                    returnToAuthorization()
                }
            }),
            sendRequest('GET', GET_FAREWALL,undefined,access).then(result =>{
                if (result && !result.statusCode){
                    dispatch(setFarewell(result.text))
                }
            }),
            sendRequest('GET', GET_COUNT_TEST, undefined,access).then(res => {
                if (res && !res.statusCode) {
                    // if (res.testsNumber === 2) {
                    //     history.push('/tasks/second')
                    // }
                    setCountTests(res.testsNumber)
                    dispatch(setCountTasks(res.testsNumber))
                    return res.testsNumber;
                }else {
                    returnToAuthorization()
                }
            })
        ]).then((result) => {
            sendRequest('GET',TEST_STATUS , undefined,access).then(res => {
                if (res && !res.statusCode && res.stage) {
                    if (res.isFinished) {
                        history.push('/' + result[0]);
                        setModal({visible: false})
                    } else {
                        setResults({loadResults: true, stage: res.stage})
                        getPageByNum(res.stage)
                        if (result[2] === 2) history.push('/tasks/second')
                    }
                }
            })
        })
        
    }

    useEffect(() => {
        loadData();
        document.title = 'Опрос – Выполнение';
    }, [])


    const returnToAuthorization = () => {
        if (uuid){
            history.push('/' + uuid)
        }else if(localStorage.getItem('uuid')){
            history.push('/' + localStorage.getItem('uuid'))
        }
    }

    const getPageByNum = (num) =>{
        switch (num){
            case 1:
            default:
                history.push('/tasks/first');
                break;
            case 2:
                history.push('/tasks/second');
                break;
            case 3:
                history.push('/tasks/third')
                break;
        }
    }


    return(
        <main>
            <Header tasks={tasks} setModal={setModal} logo={logo} countTests={countTests}/>
            <Switch>
                <Route exact path={'/tasks/first'}>
                    <FirstTask setTasks={setTasks} setModal={setModal} countTests={countTests} results={results ? results: undefined} returnToAuthorization={returnToAuthorization}/>
                </Route>
                <Route exact path={'/tasks/second'}>
                    <SecondTask setTasks={setTasks} setModal={setModal} countTests={countTests} results={results ? results: undefined} returnToAuthorization={returnToAuthorization}/>
                </Route>
                <Route exact path={'/tasks/third'}>
                    <ThirdTask setTasks={setTasks} setModal={setModal} countTests={countTests} results={results ? results: undefined} returnToAuthorization={returnToAuthorization}/>
                </Route>
            </Switch>
        </main>
    )
}

export default Tasks;
