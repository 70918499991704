import React from "react";
import style from './modal.module.scss'
import {sendRequest} from "../../helper";
import {TEST_STATUS, SEND_MAIL_PASSWORD} from "../../helper/configurationConstants";

export const INFO_MODAL = 'INFO_MODAL';
export const INSTRUCTION_MODAL = 'INSTRUCTION_MODAL';
export const CONGRATULATIONS = 'CONGRATULATIONS';
export const CONTINUE = 'CONTINUE';
export const END = 'END';
export const TASK = 'TASK';
export const EXIT = 'EXIT';
export const RESEND_PASSWORD = 'RESEND_PASSWORD';
export const PASSWORD_SENT = 'PASSWORD_SENT';
export const ERROR = 'ERROR';
export const CONNECTION_ERROR = 'CONNECTION_ERROR';
export const IDLE_MODAL = 'IDLE_MODAL';

export const getModalContent = (modal_type, task, text,type,test_count) => {
    switch (modal_type){
        default:
        case INFO_MODAL: {
            switch (type) {
                case CONGRATULATIONS: {
                    return {
                        title: 'Поздравляем!',
                        text:  <p className={style.modalContent__text}>Задание №{test_count === 2? task -1 : task}  завершено</p>,
                        button_text: 'ok'
                    }
                }
                case END: {
                    return {
                        title: 'опрос завершен',
                        text:  <p className={`${style.modalContent__textCenter} ${style.modalContent__text}`}>{text}<br/><br/>Эту страницу можно закрыть.</p>,
                        button_text: 'выйти'
                    }
                }
                case EXIT:{
                    return {
                        title: 'Вы собираетесь прервать опрос',
                        text:  <p className={style.modalContent__text} style={{textAlign: 'center'}}>Можно вернуться к опросу позже.<br/>
                            Все ответы сохранятся.</p>,
                        button_text: 'ВЫЙТИ',
                        button_text_second: 'ОСТАТЬСЯ'
                    }
                }
                case RESEND_PASSWORD:{
                    return {
                        title: 'Отправка пароля',
                        text: <p className={style.modalContent__text} style={{textAlign: 'center'}}>Текущий пароль будет направлен на e-mail: {text.email}</p>,
                        button_text: 'ОТМЕНА',
                        button_text_second: 'ОТПРАВИТЬ'
                    }
                }
                case PASSWORD_SENT: {
                    return {
                        title: 'ГОТОВО',
                        text: <p className={style.modalContent__text} style={{textAlign: 'center'}}>{text}</p>,
                        button_text: 'OK'
                    }
                }
                case ERROR: {
                    return {
                        title: 'ВНИМАНИЕ',
                        text: <p className={style.modalContent__text} style={{textAlign: 'center'}}>Вы уже прошли эту часть задания. Обновите страницу, чтобы перейти туда, где вы прервались.</p>,
                        button_text: 'Oбновить'
                    }
                }
                case CONNECTION_ERROR: {
                    return {
                        title: 'ВНИМАНИЕ',
                        text: <p className={style.modalContent__text} style={{textAlign: 'center'}}>Проверьте соединение с интернетом и попробуйте обновить страницу.</p>,
                        button_text: 'Oбновить'
                    }
                }
                case IDLE_MODAL: {
                    return {
                        title: 'ВНИМАНИЕ',
                        text: <p className={style.modalContent__text} style={{textAlign: 'center'}}>Пожалуйста, обновите страницу. Необходимо актуализировать данные для продолжения опроса.</p>,
                        button_text: 'Oбновить'
                    }
                }
                default:
                case TASK: {
                    switch (task) {
                        default:
                        case 1: {
                            return {
                                title: 'внимание',
                                text: <p className={style.modalContent__text} style={{textAlign: 'center'}}>Переход к шкале<br/><b>{text}</b></p>,
                                button_text: 'ok'
                            }

                        }
                        case 2: {
                            return {
                                title: 'внимание',
                                text: <p className={style.modalContent__text}><b>Выберите ровно 3 утверждения из списка!</b></p>,
                                button_text: 'ok'
                            }
                        }
                        case 3:{
                            return {
                                title: 'внимание',
                                text: <p className={style.modalContent__text}><b>Пожалуйста, исключите из списка ровно 5 факторов.</b><br/>Число исключенных вами факторов — {text}.</p>,
                                button_text: 'ok'
                            }
                        }
                        case 4:{
                            return {
                                title: 'будьте внимательны!',
                                text: <p className={style.modalContent__text}><b>Предлагается ответить на вопрос, что для вас БОЛЕЕ значимо в работе?</b><br/><br/>В процессе выполнения задания вы можете вернуться на один шаг назад, чтобы исправить ответ.</p>,
                                button_text: 'ok'
                            }
                        }
                    }
                }
            }
        }
        case INSTRUCTION_MODAL:{
            if (type === CONTINUE){
                return {
                    title: 'Внимание',
                    text:  <p className={style.modalContent__text} style={{textAlign: 'left'}}>
                        <b>Вы собираетесь продолжить ранее прерванный опрос.</b>
                        <br/><br/>
                        Напоминаем, кнопка инструкции будет в правом верхнем углу экрана.
                    </p>,
                    button_text: `Продолжить`
                }
            }
            let num_task = test_count === 2? task -1: task
            if (num_task <= 0)
                num_task = 1;
            switch (task){
                default:
                case 1:
                    return {
                        title: 'Инструкция',
                        text:  <p className={style.modalContent__textMore} style={{textAlign: 'left'}}>
                            <b>Вам предлагается оценить ряд понятий по нескольким признакам, представленным в виде шкал.</b>
                            <br/><br/>
                            Например: если вы оцениваете понятие «Безопасность», по шкале «Вредный — Полезный» больше как полезное, то выберите рядом с признаком «Полезный» одну из цифр 1, 2 или 3 (в зависимости от вашей личной оценки). Если же понятие «Безопасность» больше ассоциируется у вас как вредное, то  выберите (опять же, в зависимости от вашей личной оценки) одну из цифр 1, 2 или 3 рядом с признаком «Вредный».
                            <br/><br/>
                            Если хотите исправить свои ответы, то в поле «Уже оценили», расположенном ниже, выберите соответствующее понятие и измените его оценку.<br/><br/>
                            Если по каким-то причинам вы прервете опрос, не успев выполнить все задания, то в любое время сможете к нему вернуться. 
                            При этом все введенные данные сохранятся. И вы продолжите с того момента, где прервались.<br/><br/>
                            Совет:<br/>
                            1. Внимательно посмотрите на каждое понятие и постарайтесь дать как можно более точную оценку по шкале.<br/>
                            2. Отвечайте быстро, долго не задумываясь.<br/><br/>
                            <div className={style.modalContent__textLittle}>
                            Если вы прервались, а страница с опросом осталась открытой, то при возврате к ней обновите ее. 
                            Так вы удостоверитесь, что не вышли из опроса и его можно продолжить.</div>
                        </p>,
                        button_text: `Приступить к заданию №${num_task}`
                    }
                case 2:
                    return {
                        title: 'Инструкция',
                        text:  <p className={style.modalContent__textMore}>
                            <b>Вам предлагаются 19 блоков со списками утверждений.<br/><br/>
                            В каждом из блоков выберите 3 НАИБОЛЕЕ подходящих
                            для вас утверждения, закончив фразу «Сейчас я хочу...»</b><br/><br/>
                            <div className={style.modalContent__textLittle}>Если по каким-то причинам вы прервете опрос, не успев выполнить все задания, то в любое время сможете к нему вернуться. 
                            При этом все введенные данные сохранятся. И вы продолжите с того момента, где прервались.<br/><br/>
                            Если вы прервались, а страница с опросом осталась открытой, то при возврате к ней обновите ее. 
                            Так вы удостоверитесь, что не вышли из опроса и его можно продолжить.</div>
                        </p>,
                        button_text: `Приступить к заданию №${num_task}`
                    }
                case 3:
                    return {
                        title: 'Инструкция',
                        text:  <p className={style.modalContent__textMore}>
                            <b>Исключите из предлагаемого списка 5 НАИМЕНЕЕ значимых для вас факторов в работе.</b><br/>
                            Выберите их в списке и нажмите «Исключить».
                            <br/><br/>Повторяйте операцию до тех пор, пока не появится новая инструкция.<br/><br/>
                            <div className={style.modalContent__textLittle}>Если по каким-то причинам вы прервете опрос, не успев выполнить все задания, то в любое время сможете к нему вернуться. 
                            При этом все введенные данные сохранятся. И вы продолжите с того момента, где прервались.<br/><br/>
                            Если вы прервались, а страница с опросом осталась открытой, то при возврате к ней обновите ее. 
                            Так вы удостоверитесь, что не вышли из опроса и его можно продолжить.</div>
                            </p>,
                        button_text: `Приступить к заданию №${num_task}`
                    }
                case 4:
                    return {
                        title: 'Инструкция №2',
                        text: <p className={style.modalContent__textMore}>
                            <b>Предлагается ответить на вопрос, что для вас БОЛЕЕ значимо в работе?</b><br/><br/>В процессе выполнения задания вы можете вернуться на один шаг назад, чтобы исправить ответ.<br/><br/>
                            <div className={style.modalContent__textLittle}>Если по каким-то причинам вы прервете опрос, не успев выполнить все задания, то в любое время сможете к нему вернуться. 
                            При этом все введенные данные сохранятся. И вы продолжите с того момента, где прервались.<br/><br/>
                            Если вы прервались, а страница с опросом осталась открытой, то при возврате к ней обновите ее. 
                            Так вы удостоверитесь, что не вышли из опроса и его можно продолжить.</div>
                            </p>,
                        button_text: 'ok'
                    }
            }

        }
    }

}

export const getFunctionForModal = (setModal, modal,modal_type, task, text,type,history,count_task,access) => {
    if (modal_type === INSTRUCTION_MODAL) {
        if (type !== CONTINUE) {
            setModal({...modal, visible: false});
        } else {
            setModal({...modal, visible: true, modal_type: INSTRUCTION_MODAL,text: text, task: task,type: INFO_MODAL});

            // if ((count_task === 2 && task === 2 ) || (task === 3 && count_task === 3))
            // else if ((count_task === 2 && task === 3) || (task === 4 && count_task === 3))
            //     setModal({...modal, visible: true, modal_type: INFO_MODAL,text: text, task: task,type: TASK});

        }
    } else {
        switch (type){
            default:
            case TASK:{
                setModal({...modal, visible: false});
                break;
            }
            case ERROR: {
                window.location.reload();
                break;
            }
            case CONNECTION_ERROR: {
                window.location.reload();
                break;
            }
            case IDLE_MODAL: {
                window.location.reload();
                break;
            }
            case CONGRATULATIONS:{
                if (task === 1)
                    if (count_task > 1)
                        history.push('/tasks/second');
                    else {
                        sendRequest('POST', TEST_STATUS, JSON.stringify({finished: true}), access, setModal).then(r =>{
                        })
                        setModal({...modal, visible: true, modal_type: INFO_MODAL, text: text, task: task, type: END});
                    }
                else if (task === 2) {
                    history.push('/tasks/third');
                } else {
                    sendRequest('POST', TEST_STATUS, JSON.stringify({finished: true}), access, setModal).then(r =>{
                    })
                    setModal({...modal, visible: true, modal_type: INFO_MODAL,text: text, task: task, type:END});
                }
                break;
            }
            case END:{
                setModal({...modal, visible: false});
                history.push('/');
                break;
            }
            case EXIT:{
                if (task === 1){
                    setModal({...modal, visible: false});
                    history.push('/');
                }else
                    setModal({...modal, visible: false});
                break;
            }
            case RESEND_PASSWORD: {
                if (task === 1) {
                    setModal({...modal, visible: false});
                } else {
                    let message = {
                      userId: text.userId,
                      title: 'Пароль к опросу',
                      content: 'Здравствуйте!\n' + 
                      'Сообщаем пароль от вашей ссылки на опрос: ',
                    }
                    sendRequest('POST', SEND_MAIL_PASSWORD, JSON.stringify(message), access, setModal).then(() => {
                        setModal({...modal, visible: true, modal_type: INFO_MODAL, type: PASSWORD_SENT, text: 'Пароль направлен на указанный e-mail.' })
                    }).catch(() => {
                        setModal({...modal, visible: true, modal_type: INFO_MODAL, type: PASSWORD_SENT, text: 'Ошибка отправки пароля.' })
                    })
                }
                break;
            }
        }
    }

}
