import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {sendRequest} from "../../../../helper";
import {
    TEST_ATTENTIVENESS,
    TEST_RESULT,
    TEST_RESULT_EXCLUSION,
    TEST_SINCERITY,
    TEST_STAGE
} from "../../../../helper/configurationConstants";
import ExcludePart from "./ExcludePart";
import ComparePart from "./ComaprePart";
import {CONGRATULATIONS, CONTINUE, ERROR, INFO_MODAL, INSTRUCTION_MODAL, TASK} from "../../../Modals/modalHelper";

const ThirdTask = ({setTasks,setModal,countTests,results,returnToAuthorization,...props}) => {
    const [taskData, setTaskData] = useState({})
    const [progress, setProgress] = useState({start: 0});
    const [stage, setStage] = useState(1);
    const access = useSelector((state) => state.access.access)
    const [answers, setAnswers] = useState({})
    const [analyseAnswers, setAnalyseAnswers] = useState([]);
    const [pairs, setPairs] = useState();
    const [visible, setVisible] = useState(true);
    const [loadedData, setLoadedData] = useState(false);
    const [exclusionAnswers, setExclusionAnswers] = useState({});

    const history = useHistory()

    const loadResults = (arr) => {
        sendRequest('GET',TEST_RESULT + '/stage/' + 3, undefined,access).then(result => {
            if (result && !result.statusCode) {
                setLoaded(result,arr)
            } else {
                returnToAuthorization()
            }
        })
    }

    useEffect(() => {
        if (countTests === 1)
            history.push('/tasks/first');
    }, [countTests])


    useEffect(() => {
        const pageAccessedByReload = (
            (window.performance.navigation && window.performance.navigation.type === 1) ||
              window.performance
                .getEntriesByType('navigation')
                .map((nav) => nav.type)
                .includes('reload')
        );
        if (loadedData){
            if (stage < 6)
                setTasks(3)
            else
                setTasks(4)
            if (Object.keys(answers).length === 0)
                setModal({visible:true,
                    modal_type: INSTRUCTION_MODAL,
                    task :3,
                })
            else if( Object.keys(answers).length === 5)
                setModal({
                    visible: true,
                    modal_type: INSTRUCTION_MODAL,
                    task: 4
                })
            else if (!pageAccessedByReload) {
                setModal({
                    visible: true,
                    modal_type: INSTRUCTION_MODAL,
                    type: CONTINUE,
                    task: stage < 6 ? 3: 4,
                    close: Boolean(answers && Object.keys(answers).length > 0)
                })
            }
        }
    }, [loadedData])


    const setLoaded = (result,arr ) => {
        if (result){
            let copy_answers = {};
            let copy_analyze_answers = {}
            let index_id = 1
            let copy_arr = arr
            let count = 0
            result.forEach((element) => {
                if (count === 5){
                    count = 0
                    index_id++
                }
                count++;
                if (!copy_answers[index_id])
                    copy_answers[index_id] = {}
                if (!copy_analyze_answers[index_id])
                    copy_analyze_answers[index_id] = {}
                copy_answers[index_id][element.num] = true
                copy_analyze_answers[index_id][element.num] = element.text
            })
            /*Object.keys(copy_answers).forEach(step => {
                Object.keys(copy_answers[step]).forEach(key => {
                    if (copy_answers[step][key])
                        delete copy_arr[key]
                })
            })*/
            let answersKeys = [];
            Object.keys(copy_answers).forEach(step => {
                if (step < 6) {
                    Object.keys(copy_answers[step]).forEach(key => {
                        if (copy_answers[step][key]) answersKeys.push(key)
                    })
                }
            })
            let exclusionAnswers = {};
            Object.keys(copy_arr).forEach((key) => {
                if (!answersKeys.includes(key)) {
                    exclusionAnswers[key] = copy_arr[key];
                }
            })
            setExclusionAnswers(exclusionAnswers);
            setAnalyseAnswers(copy_analyze_answers)
            setAnswers(copy_answers)
            setStage((index_id === 1 && result.length === 0)? 1 : index_id+ 1)
            setProgress({...progress, start: result.length > 0 ? index_id: 0, end: 205})
            setTaskData({...taskData, tasks: copy_arr})
        }
        setLoadedData(true)
    }

    useEffect(() => {
        window.scrollTo(0,0)
        setTasks(3)
        sendRequest('GET',TEST_STAGE + '3',undefined,access, setModal).then((res)=>{
            if (res){
                if (!res.statusCode){
                    setTaskData({...taskData, tasks: res});
                    setProgress({...progress, end: 205})
                    loadResults(res)
                }else
                    returnToAuthorization()
            }else
                returnToAuthorization()

        })

    },[])

    const changeAnswer = (id) => {
        let copy_answer = answers[stage] ? answers[stage] : {};
        let count = 0
        Object.keys(copy_answer).forEach(key => {
            if (copy_answer[key])
                count++;
        })
        if (count < 5 || (Object.keys(copy_answer).indexOf(id) !== -1 && count <= 5 && copy_answer[id])) {
            copy_answer[id] = !Boolean(copy_answer[id]);
            setAnswers({...answers, [stage]: copy_answer})
        }
    }

    const saveExcludedAnswers = (info) => {
        return sendRequest("POST", TEST_RESULT_EXCLUSION, JSON.stringify(info), access, setModal).then(r => {
            if (!r.statusCode) {
                if (info.phase === 5){
                    setPairs(r.pairs)
                    setProgress({...progress, start: progress.start + 1})
                    setStage(stage + 1)
                }
            } else {
                setModal({
                    visible: true,
                    modal_type: INFO_MODAL,
                    type: ERROR
                });
                window.alert = function(text) {
                    console.log('tried to alert ' + text)
                };
            }
        })
    }

    const excludeAnswers = () => {
        if (stage < 6) {
            let copy_tasks = taskData.tasks;
            let elements = [];
            if (answers[String(stage)]) {
                Object.keys(answers[String(stage)]).forEach(key => {
                    if (answers[stage][key]) {
                        elements.push(key)
                    }
                })
                if (elements.length > 4) {
                    if (stage < 3){
                        let arr = {}
                        elements.forEach(key => {
                            arr[key] = copy_tasks[key];
                        })
                        setAnalyseAnswers({...analyseAnswers, [stage]: arr});
                    }
                    let save_arr = []
                    elements.forEach(key => {
                        save_arr.push(Number(key))
                        delete exclusionAnswers[key]
                    })
                    let data = {
                        phase: stage,
                        nums: save_arr
                    }
                    if (stage === 5){
                        setVisible(false)
                        saveExcludedAnswers(data);
                        setTimeout(()=>{
                            
                        },800)
                    }else {
                        saveExcludedAnswers(data);
                        setProgress({...progress, start: progress.start + 1})
                        setStage(stage + 1)
                    }
                } else {
                    setModal({
                        visible: true,
                        modal_type: INFO_MODAL,
                        task: 3,
                        text: elements ? elements.length : 0,
                        type: TASK,
                    })
                }
                if (stage === 6) {
                    setModal({
                        visible: true,
                        modal_type: INFO_MODAL,
                        task: 4,
                        type: TASK,
                    })
                }
            }else {
                setModal({
                    visible: true,
                    modal_type: INFO_MODAL,
                    task: 3,
                    text: elements ? elements.length : 0,
                    type: TASK,
                })
            }
        }
    }

    const getValue = (id) =>{
        let copy_answer = answers[stage] ? answers[stage] : {};
        return copy_answer[id]
    }

    if (loadedData){
        if (stage < 6){
            return (
                <ExcludePart visible={visible} changeAnswer={changeAnswer} setModal={setModal} excludeAnswers={excludeAnswers}
                             getValue={getValue} progress={progress} taskData={exclusionAnswers}/>
            )
        }
        else
            return (
                <ComparePart setTasks={setTasks} setProgress={setProgress} setModal={setModal} analyseAnswers={analyseAnswers}
                             firstPairs={pairs} returnToAuthorization={returnToAuthorization}
                             progress={progress} taskData={taskData} setTaskData={setTaskData}/>
            )
    }else
        return  <main/>
}

export default ThirdTask;
