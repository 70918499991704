import React, {useEffect, useState} from 'react';
import style from './authorization.module.scss'
import {useHistory} from "react-router-dom";
import {sendRequest} from "../../../helper";
import {
    GET_USER_IMAGE,
    USERS_AUTH,
    GET_USER_DATA,
    GET_GREETING,
    TEST_STATUS,
    BACK_API,
    HOST
} from "../../../helper/configurationConstants";
import {useDispatch, useSelector} from "react-redux";
import {setAccess} from "../../../store/reducers/tokenStorage/accessStore";
import {setUserData} from "../../../store/actions/userActions";
import classnames from 'classnames';
import { RESEND_PASSWORD, INFO_MODAL } from '../../Modals/modalHelper';

const Authorization = ({logo, setLogo, setModal, ...props}) => {
    const [password, setPassword] = useState('');
    const history = useHistory();
    const dispatch = useDispatch();
    const uuid = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
    const access = useSelector((state) => state.access.access)
    const [incorrect, setIncorrect] = useState(false);
    const [usePassword, setUsePassword] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [status, setStatus] = useState({});
    const [testIsFinished, setTestIsFinished] = useState(false);
    const [testOpened, setTestOpened] = useState(false);
    const [emailInfo, setEmailInfo] = useState({});


    const  signIn =  () => {
        if (usePassword){
            if (password.trim() === '') {
                setIncorrect(true)
                return
            }
        }
        let uuid = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
        let data = {
            "password" : password ? password.trim():''
        }
        sendRequest('POST', USERS_AUTH + uuid, JSON.stringify(data), null, setModal).then(result =>{
            let incorrect;
            if (result && !result.statusCode) {
                dispatch(setAccess(result.token));
                dispatch(setUserData(result))
                setIncorrect(false)
                incorrect = false;
                return [incorrect, result.token];
            }else {
                if (!usePassword) {
                    setUsePassword(true);
                } else {
                    setIncorrect(true);
                }
                incorrect = true;
                return [incorrect, null];
            }
        }).then(res => {
            if (!res[0]) getStatusAndGreeting(res[1])
        })
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
           signIn();
        }
    }

    useEffect(() => {
        document.title = 'Вход в опрос';
        setModal({visible: false});
        sendRequest('POST', GET_USER_IMAGE, JSON.stringify({userId: uuid}), access).then(res => {
            if (res && res.path)
                setLogo(HOST + 'static/' + res.path);
        })
        sendRequest('GET', '/users/' + uuid,undefined,access, setModal).then(res => {
            if (res && !res.statusCode){
                setUsePassword(res.usePassword)
                setEmailInfo({
                    email: res.email,
                    userId: res.id
                })
                setTestIsFinished(res.isFinished)
                if (res.isFinished) document.title = 'Опрос пройден';
                //if (res.isFinished) history.push('/');
            }
            else if (res.statusCode === 404){
                setIsDeleted(true)
            }
        })
    },[])

    const getStatusAndGreeting = (access) => {
        Promise.all([
            sendRequest('GET',TEST_STATUS , undefined,access).then(res => {
                if (res && !res.statusCode && res.stage) {
                    if (res.isFinished) {
                        setTestIsFinished(true);
                        setModal({visible: false});
                        document.title = 'Опрос пройден';
                    }
                    setStatus(res)
                    return res;
                }
            }),
            sendRequest('GET', GET_GREETING,undefined,access).then(result =>{
                if (result && result.text){
                    return [result.text, result.title];
                }
            })
        ]).then(res => {
            if (!res[0]) {
                if (!res[1] || (res[1][1].trim() === '' && res[1][0].trim() === '') || res[1][0].trim() === '') {
                    getPageByNum(status.stage);
                    setTestOpened(true);
                } else {
                    history.push('/lets_start')
                }
            } else if (!res[0].isFinished) {
                getPageByNum(res[0].stage)
                setTestOpened(true);
            }
        })
    }

    const getPageByNum = (num) =>{
        switch (num){
            case 1:
            default:
                history.push('/tasks/first');
                break;
            case 2:
                history.push('/tasks/second');
                break;
            case 3:
                history.push('/tasks/third')
                break;
        }
    }

    return(
        <main className={style.authorizationPage} >
            <div className={style.container}>
                <div className={classnames(style.authorizationContent, {
                    [style.withoutPassword]: !usePassword || testIsFinished || testOpened
                })}>
                    <p className={style.authorizationContent__title}>{testIsFinished ? 'ДАННЫЙ ОПРОС ЗАВЕРШЕН' : (!isDeleted? testOpened? 'Идет выполнение заданий опроса...' : 'ВХОД В ОПРОС' : 'ОПРОС УДАЛЕН')}</p>
                    {uuid && uuid.trim() !== '' && !testIsFinished ?
                        <>
                            {logo ? <img className={style.authorizationContent__logo_image} src={logo} alt={'logo'}/> :
                                <p className={style.authorizationContent__logo}/>
                            }
                            {usePassword && <p className={style.authorizationContent__passwordLabel}>Введите пароль</p>}
                            {isDeleted && <p className={style.authorizationContent__passwordLabel}>Вы пытаетесь перейти по ссылке на удаленный опрос.</p>}
                            {usePassword &&
                            <input placeholder={'Пароль'} className={style.authorizationContent__passwordInput}
                                value={password} onChange={e => setPassword(e.target.value)} type={'password'} onKeyDown={handleKeyDown}/>
                            }
                            {incorrect && usePassword && <p className={style.errorMessage}>Неверно введен пароль</p>}
                            {!isDeleted && !testOpened && !testIsFinished &&
                            <button className={style.authorizationContent__button} onClick={() => {
                                signIn()
                            }}>Войти</button>}
                            {usePassword &&
                            <button className={style.authorizationContent__helpButton}>
                                <pre className={style.authorizationContent__helpButton_text}>
                                    {"Нет пароля?\n"}
                                    <p className={style.authorizationContent__helpButton_bold} onClick={() => {
                                        setModal({
                                            visible: true,
                                            modal_type: INFO_MODAL,
                                            task: 1,
                                            type: RESEND_PASSWORD,
                                            text: {
                                                email: emailInfo.email,
                                                userId: emailInfo.userId
                                            }
                                        })
                                    }
                                    }>{"Запросить пароль"}</p>
                                </pre>
                            </button>
                            }
                        </>: !testOpened &&
                        <p className={style.authorizationContent__passwordLabel}>{testIsFinished ? '' : 'Вы перешли по ссылке с неустановленным идентификатором.'}</p>
                    }
                </div>
            </div>
        </main>
    )
}

export default Authorization;
