import {SET_COUNT_TASKS, SET_FAREWELL, SET_STATISTIC, SET_USER_DATA} from "../actions/userActions";

const initialState = {
    role: undefined,
    uuid: undefined,
    statistic: {},
    count_tasks: null,
    farewell: ''
};

export function userReducer(state  = initialState,action) {
    const {type, payload} = action;
    switch (type) {
        case SET_STATISTIC:
            return {...state, statistic: payload};
        case SET_USER_DATA:{
            return {
                ...state,
                role:  payload.role,
                uuid: payload.uuid
            }
        }
        case SET_COUNT_TASKS:
            return {...state, count_tasks: payload}
        case SET_FAREWELL:
            return {...state, farewell: payload}
        default:
            return {...state};
    }
}
