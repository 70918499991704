import './App.css';
import {Route, Switch } from 'react-router-dom';
import Authorization from "./Components/Pages/Authorization/Authorization";
import React, {useState} from "react";
import ConfirmAuthorization from "./Components/Pages/Authorization/ConfirmAuthorization";
import Tasks from "./Components/Pages/Tasks/Tasks";
import ModalComponent from "./Components/Modals/Modal";
import Modal  from '@material-ui/core/Modal';
import { useEffect } from 'react';

const App = () => {
    const [logo,setLogo] = useState();
    const [modal, setModal] = useState();
    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
        if (modal) {
            if (modal.visible) {
                document.body.style.overflow = "hidden";
                document.documentElement.style.position = "fixed";
                document.documentElement.style.width = "100%";
            } else {
                document.body.style.overflow = "unset";
                document.documentElement.style.position = "unset";
            }
        }
    }, [modal])

    useEffect(() => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari) {
            setIsSafari(true);
            document.documentElement.style.overflow = "hidden";
            document.documentElement.style.height = "100%";
            document.documentElement.style.width = "100%";
            document.documentElement.style.height = "100vh";
            document.documentElement.style.width = "100vw";
            document.documentElement.style.margin = "0";
            document.documentElement.style.padding = "0";
        }
    }, [])
    

    return (
        <div className={`App ${modal && modal.visible && "overflowHidden"} ${isSafari && "App-safari"}`}>
            {(modal && modal.visible) &&
            <Modal open={modal.visible}>
                <ModalComponent modal={modal}
                                setModal={setModal}
                                modal_type={modal.modal_type}
                                text={modal.text} task={modal.task}
                                close={modal.close}
                                type={modal.type}/>
            </Modal>
            }
            <Switch>
                <Route exact path={'/'}>
                    <Authorization setModal={setModal} logo={logo} setLogo={setLogo}/>
                </Route>
                <Route exact path={'/authorization'}>
                    <Authorization setModal={setModal} logo={logo}  setLogo={setLogo}/>
                </Route>
                <Route exact path={'/lets_start'}>
                    <ConfirmAuthorization setModal={setModal} logo={logo}/>
                </Route>
                <Route exact path={'/tasks/*'}>
                    <Tasks setModal={setModal} logo={logo} setLogo={setLogo}/>
                </Route>
                <Route  path={'*'}>
                    <Authorization setModal={setModal} logo={logo}  setLogo={setLogo}/>
                </Route>
            </Switch>
        </div>
    )
}

export default App;
