import React, {useEffect, useState} from 'react';
import style from "./authorization.module.scss";
import {sendRequest} from "../../../helper";
import {useHistory} from 'react-router-dom'
import {GET_GREETING, GET_USER_DATA, TEST_STATUS,} from "../../../helper/configurationConstants";
import {useSelector} from "react-redux";
import classnames from 'classnames';

const ConfirmAuthorization = ({logo, setModal, ...props}) => {

    const [greeting, setGreeting] = useState('')
    const access = useSelector((state) => state.access.access)
    const [describe, setDescribe] = useState('')
    const [neededStage, setNeededStage] = useState();
    const [testOpened, setTestOpened] = useState(false);

    const history = useHistory();

    useEffect(() => {
        document.title = 'Опрос – Начало';
        setModal({visible: false});
        sendRequest('GET', GET_GREETING,undefined,access).then(result =>{
           if (result && result.text){
               setDescribe(result.text)
               setGreeting(result.title)
           }
        })

        sendRequest('GET',TEST_STATUS , undefined,access).then(res => {
            if (res && !res.statusCode && res.stage) {
                if (res.isFinished) {
                    history.push('/' + localStorage.getItem('uuid'));
                    setModal({visible: false})
                }
                setNeededStage(res.stage)
            }
        })
        // sendRequest('GET', GET_USER_DATA,undefined,access).then(res => {
        //     if (res && !res.statusCode){
        //         setGreeting(res.name + ' ' + res.secondName + ', добро пожаловать!')
        //     }
        // })
    }, [])

    const getPageByNum = (num) =>{
      switch (num){
          case 1:
          default:
              history.push('/tasks/first');
              break;
          case 2:
              history.push('/tasks/second');
              break;
          case 3:
              history.push('/tasks/third')
              break;
      }
    }

    return(
        <main className={style.authorizationPage} style={{height: window.screen.height}}>
            <div className={style.container}>
                <div className={classnames(style.authorizationContent, {
                    [style.withoutPassword]: true
                })}>{testOpened ? <p className={style.authorizationContent__title}>Идет выполнение заданий опроса...</p>:
                    <>
                    {logo ? <img className={style.authorizationContent__logo_image} src={logo} alt={'logo'}/> :
                        <p className={style.authorizationContent__logo}/>}
                        <p className={style.authorizationContent__helloText}>
                            <span className={style.authorizationContent__helloText_title}>{greeting}</span>
                            <br/>
                            <br/>
                            <p className={style.authorizationContent__helloText_description}>{describe}</p>
                        </p>
                    <button className={style.authorizationContent__button}
                        onClick={() => {
                            getPageByNum(neededStage);
                            setTestOpened(true);
                        }}
                    >пройти опрос</button>
                    </>}
                </div>
            </div>
        </main>
    )
}

export default ConfirmAuthorization;
