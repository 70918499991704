import React, {useEffect, useState} from "react";
import style from "./third_task.module.scss";
import {CloseIcon} from "../../Icons/Icons";
import classNames from 'classnames'
import LinearProgress from "@material-ui/core/LinearProgress";
import {CONGRATULATIONS, ERROR, EXIT, INFO_MODAL, INSTRUCTION_MODAL, TASK} from "../../../Modals/modalHelper";
import {useSelector} from "react-redux";
import {sendRequest} from "../../../../helper";
import {
    GET_PAIR,
    TEST_RESULT,
    TEST_RESULT_COMPARE,
    TEST_STAGE,
    TEST_STATUS
} from "../../../../helper/configurationConstants";

const ComparePart = ({setTasks,analyseAnswers,setModal,progress, setProgress,taskData,setTaskData,returnToAuthorization, firstPairs,...props}) => {

    const farewell = useSelector((state) => state.user.farewell)
    const [selectedElements, setSelectedElements] = useState([]);
    const [elements, setElements] = useState([]);
    const [position, setPosition] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [isBack, setIsBack] = useState(false)
    const [analyse, setAnalyse] = useState({'1': analyseAnswers['1'], '2': analyseAnswers['2']});
    const access = useSelector((state) => state.access.access);
    const [pairs, setPairs] = useState(firstPairs);
    const [locked, setLocked] = useState(false);


    const choosePosition = (key) =>{
        if (!locked) {
            setLocked(true)
            setIsBack(false)
            savePosition({pair: position, result: key});
            setTimeout(() => {
                let copy_answers = taskData.select ? taskData.select : {};
                copy_answers[key] = copy_answers[key] ? copy_answers[key] + 1 : 1;
                let arr = selectedElements;
                arr.push(key);
                setSelectedElements(arr)
                setTaskData({...taskData, select: copy_answers})
                if (progress.start === 194) {
                    getMostActual(analyse, copy_answers);
                }
                if (progress.start < 204) {
                    setProgress({...progress, start: progress.start + 1})
                    setPosition(position + 1);
                } else {
                    sendRequest('POST', TEST_STATUS, JSON.stringify({finished: true}), access).then(r => {
                    })
                    setModal({
                        visible: true,
                        modal_type: INFO_MODAL,
                        task: 3,
                        text: farewell,
                        type: CONGRATULATIONS,
                    })
                }
                setActiveStep(0)
                setLocked(false)
            }, 300)
        }
    }

    const savePosition = (info) => {
        sendRequest('POST', TEST_RESULT_COMPARE, JSON.stringify(info), access, setModal).then(res => {
            if (res.statusCode) {
                setModal({
                    visible: true,
                    modal_type: INFO_MODAL,
                    type: ERROR
                });
                window.alert = function(text) {
                    console.log('tried to alert ' + text)
                };
            }
        })
    }

    const getMostActual = (anal, selectedElem, tasksElem, pairsElem) => {
        console.log(anal)
        let indexes = [];
        let most_actual = [];
        let max = 0
        let select = selectedElem? selectedElem: taskData.select
        let tasks = tasksElem? tasksElem: taskData.tasks
        console.log(select)
        Object.keys(select).forEach(key => {
            if (select[key] >= 11) {
                indexes.push({
                    key: key,
                    val: select[key]
                })
                if (select[key] > max)
                    max = select[key];
            }
        })
        indexes = indexes.sort((a,b)=> {
            if (a.val > b.val)
                return -1;
            else return 1;
        })
        console.log(indexes)
        if (indexes.length >= 5) {
            while(most_actual.length < 5 && max >= 11){
                indexes.forEach(elem => {
                    if (most_actual.length < 5){
                        most_actual.push({id: elem.key, value: tasks[elem.key]})
                        max--;
                    }
                })
            }
            if (indexes.length < 5){
                let j = 0;
                for (let i = indexes.length; i < 5; i++){
                    most_actual.push({
                        id: indexes[j].key,
                        value: tasks[indexes[j].key]
                    })
                }
            }
            console.log(most_actual)
            let arr = pairsElem ? pairsElem: [...elements];
            Object.keys(anal).forEach(key => {
                Object.keys(anal[key]).forEach((key_item,index) => {
                    if (index % 2 === 0) {
                        arr.push({first: {id: key_item, value: anal[key][key_item]}, second: most_actual[index]});
                    } else {
                        arr.push({first: most_actual[index], second: {id: key_item, value: anal[key][key_item]}});
                    }
                })
            })
            setElements(arr);
            console.log(arr)
        } else {
            setTimeout(() => {
                setProgress({...progress, start: 205})
            }, 500)
            sendRequest('POST', TEST_STATUS, JSON.stringify({finished: true}), access).then(r => {
            })
            setModal({
                visible: true,
                modal_type: INFO_MODAL,
                task: 3,
                text: farewell,
                type: CONGRATULATIONS,
            })
        }
    }


    const goBack = () =>{
        setIsBack(true)
        let arr = selectedElements;
        let copy_answers = taskData.select ? taskData.select : {};
        let index = arr.pop();
        copy_answers[index] = copy_answers[index] ? copy_answers[index] - 1 : 0;
        setTaskData({...taskData, select: copy_answers});
        setSelectedElements(arr);
        setPosition(position - 1);
        setProgress({...progress, start: progress.start - 1})
    }

    useEffect(() => {
        if (pairs) setVariantPairs(pairs)
    }, [pairs])

    useEffect(() => {
        setTasks(4)
        getPairs()
        setProgress({...progress, start: 5})
        if (progress.start === 5) {
            setModal({
                visible: true,
                modal_type: INSTRUCTION_MODAL,
                task: 4,
                type: TASK,
            })
        }
    },[])

    const getPairs = () => {
        sendRequest('GET', GET_PAIR, undefined, access, setModal).then(res => {
            if (res && !res.statusCode){
                setPairs(res)
                let count = 5;
                let answers = [];
                let select = {};
                //let analyse = {};
                sendRequest('GET',TEST_STATUS , undefined,access).then(res => {
                    if (res && !res.statusCode) {
                        if (res.isFinished) {
                            returnToAuthorization();
                            setModal({visible: false})
                        }
                    }
                })
                sendRequest('GET', TEST_RESULT + '/stage/3', undefined,access).then(result => {
                    if (result && !result.statusCode){
                        result.forEach(element => {
                            if (element.result) {
                                count += element.result[1]
                                select[element.num] = element.result[1];
                                answers.push(Number(element.num));
                            }
                            /*if (element.result && (element.result[2] === 1 || element.result[2] === 2)) {
                                if (!analyse[element.result[2]])
                                    analyse[element.result[2]] = {}
                                analyse[element.result[2]][element.num] = element.text;
                            }*/
                        })
                        if (count >= 194){
                            getMostActual(analyse, select, taskData.tasks, setVariantPairs(res));
                        }
                    }else {
                        returnToAuthorization()
                    }
                    //setAnalyse(analyse)
                    setSelectedElements(answers)
                    setTaskData({...taskData, select: select})
                    setPosition(count - 5)
                    setProgress({...progress, start: count})
                })
            } else {
                returnToAuthorization()
            }
        })
    }

    const setVariantPairs = (pairs) =>{
        let copy_pairs = pairs;
        let array = [];
        if (pairs) {
            copy_pairs.forEach(element => {
                array.push({
                    first: {
                        id: element.first.num,
                        value: element.first.text
                    },
                    second: {
                        id: element.second.num,
                        value: element.second.text
                    },
                })
            })
            setElements(array)
        }
        return array;
    }

    const closeWindow = () => {
        setModal({
            visible: true,
            modal_type: INFO_MODAL,
            task: 1,
            type: EXIT,
            text: progress.start
        })
    }


    return(
        <main className={classNames(style.task)}>
            <div  className={style.task__main + ' ' + style.compare}>
                {/* <button  className={style.task__main_close} onClick={() => closeWindow()}>
                    {CloseIcon}
                </button> */}
                <LinearProgress variant="determinate" value={taskData.tasks ?
                    (progress.start / 205)*100: 0
                } />
                <div className={style.compareTitle}>
                    <pre className={style.compareTitle__text}>{"Что для вас является БОЛЕЕ\n значимым в работе?"}</pre>
                </div>
                {elements.length > 0 && elements[position] &&
                    <div className={style.compare__select}>
                        <button className={classNames(style.compare__select_button, {[style.activeSelect]: activeStep === 1})} onClick={() => {
                            setActiveStep(1)
                            choosePosition(elements[position].first.id)
                        }}>
                            {elements[position].first.value}
                        </button>
                        <p className={style.compare__select_text}>или</p>
                        <button className={classNames(style.compare__select_button, {[style.activeSelect]: activeStep === 2})} onClick={() => {
                            setActiveStep(2)
                            choosePosition(elements[position].second.id)
                        }}>
                            {elements[position].second.value}
                        </button>
                    </div>
                }
                {position > 0 && !isBack && progress.start !== 195 && 
                    <button className={style.compareBack} onClick={() => goBack()}>
                        Назад
                    </button>
                }
            </div>
        </main>
    )
}

export default ComparePart;
