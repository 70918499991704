import React, {useEffect, useState} from 'react';
import style from './header.module.scss'
import classNames from 'classnames'
import {INSTRUCTION_MODAL} from "../Modals/modalHelper";

const Header = ({tasks,setModal,logo,countTests,...props}) => {
    const [stage, setStage] = useState();
    const [mouseOut, setMouseOut] = useState(false);
    const [hintOpen, setHintOpen] = useState({
        0: false,
        1: false,
        2: false
    });
    
    const getScreenWidth = () => {
        return window.screen.width;
    }


    const getTask = (num) => {
        switch (num){
            default:
            case 1:
                if (countTests === 2)
                    return 'second';
                else
                    return 'first';
            case 2:
                if (countTests === 2)
                    return 'third';
                else
                    return 'second';
            case 3:
                return 'third';
        }
    }

    const getStage = () =>{
        if (window.location.pathname.includes('first'))
            return 1;
        else if (window.location.pathname.includes('second'))
            if (countTests === 2)
                return 1;
            else
                return 2;
        else if (window.location.pathname.includes('third'))
            if (countTests === 2)
                return 2;
            else
                return 3;
    }

    useEffect(() => {
        //setStage(getStage())
        window.document.addEventListener("mouseout", (event) => {
            setMouseOut(true);
        });
        window.document.addEventListener("mouseover", (event) => {
            setMouseOut(false);
        });
        window.addEventListener("blur", (event) => {
            setMouseOut(true);
        });
        window.addEventListener("focus", (event) => {
            setMouseOut(false);
        });
    }, [])

    useEffect(() => {
        setStage(tasks);
    }, [tasks])

    useEffect(() => {
        //setStage(getStage())
    }, [window.location.pathname, countTests])

    const getArr = () => {
        switch (countTests){
            case 1:
                return [1];
            case 2:
                return [1,2];
            case 3:
            default:
                return [1,2,3];
        }
    }

    return(
        <header className={style.header}>
            {logo ? <div className={style.header__imageContainer}><img  className={style.header__logo_image} src={logo} alt={'logo'}/></div> :
            <p  className={style.header__logo}/>}
            <div  className={classNames(style.header__stages,
                {[style.header__center]: getArr().length === 1})}>
                {getArr().map((item, index) => {
                    let currentStage = stage;
                    if (countTests === 2) currentStage = stage - 1;
                    if (countTests === 2 && stage > 2) currentStage = 2;
                    if (countTests === 3 && stage > 3) currentStage = 3;
                    return(
                        <button className={classNames(style.header__stages_button,
                            {
                                [style.activeStage]: currentStage === item,
                                [style.hintOpen]: hintOpen[index]
                            })}
                            onClick={() => {
                                if (getScreenWidth() < 590) {
                                    setHintOpen({
                                        ...hintOpen,
                                        [index]: !hintOpen[index]
                                    })
                                }
                            }}
                            >
                            <p className={style.longText}>Задание №{item}</p>
                            <p className={style.shortText}><span style={{
                                display: hintOpen[index] ? "inline-block" : "none"
                                }}>Задание №</span><span style={{display: "inline-block"}}>{item}</span></p>
                        </button>
                    )
                })}
            </div>
            <div className={style.header__instructionContainer}>
                <button  title={'Инструкция'}
                    className={`${style.header__FAQ} ${mouseOut && style.header__FAQLighted}`} onClick={() => {
                    setModal({visible:true,
                        modal_type: INSTRUCTION_MODAL,
                        task : stage,
                        close: true
                    })
                }}>
                    {QuestionIcon}
                </button>
            </div>
        </header>
    )
}

const QuestionIcon = (
    <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.99945 9.92L6.17945 9.28C5.97945 9.1 5.81945 8.76 5.81945 8.44C5.81945 6.76 9.15945 6.54 9.15945 3.62C9.15945 1.76 7.57945 0.459999 4.99945 0.459999C3.27945 0.459999 1.67945 1.16 0.619453 2.4L2.23945 4.22C2.85945 3.48 3.69945 2.98 4.69945 2.98C5.79945 2.98 6.25945 3.5 6.25945 4.12C6.25945 5.5 3.33945 5.78 3.33945 8.16C3.33945 8.86 3.57945 9.46 3.99945 9.92ZM4.95945 14.22C5.83945 14.22 6.57945 13.48 6.57945 12.6C6.57945 11.72 5.83945 10.98 4.95945 10.98C4.07945 10.98 3.33945 11.72 3.33945 12.6C3.33945 13.48 4.07945 14.22 4.95945 14.22Z" fill="#4DB0DD"/>
    </svg>
);

export default Header;
