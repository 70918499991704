export const FIRST_STAGE_SCALE =[
    {first: 'Вредный', second: 'Полезный'},
    {first: 'Временный', second: 'Постоянный'},
    {first: 'Лишний', second: 'Необходимый'},
    {first: 'Противный', second: 'Приятный'},
    {first: 'Изменчивый', second: 'Неизменный'},
    {first: 'Ненужный', second: 'Нужный'},
    {first: 'Плохой', second: 'Хороший'},
    {first: 'Мимолетный', second: 'Вечный'},
    {first: 'Второстепенный', second: 'Неотложный'}
];