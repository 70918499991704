import React, {useEffect, useState} from "react";
import {CONTINUE, EXIT, END, getFunctionForModal, getModalContent, INFO_MODAL, INSTRUCTION_MODAL, RESEND_PASSWORD, ERROR} from "./modalHelper";
import style from './modal.module.scss'
import classNames from 'classnames'
import {useHistory} from 'react-router-dom'
import {useSelector} from "react-redux";

const Modal = ({modal,setModal,modal_type, type, task,text,...props}) => {
    const test_count = useSelector((state) => state.user.count_tasks)
    const [num_test,setNumTest] = useState(1);

   const [info, setInfo] = useState(getModalContent(modal_type,task,text,type,test_count));
   useEffect(() => {
       setInfo(getModalContent(modal_type,task,text,type,test_count))
   }, [modal_type,type])

    useEffect(() => {
        setInfo(getModalContent(modal_type,task,text,type,test_count))
    },[modal,setModal,modal_type, type, task,text,test_count])

    useEffect(() =>{
        if (document.querySelectorAll('[role="presentation"]').length > 0){
            // document.querySelectorAll('[role="presentation"]')[0].style.overflow = 'scroll'
            // document.querySelectorAll('[role="presentation"]')[0].style.height = '120%'
        }
    },)

    useEffect(() => {
        if (type === END) {
            document.title = 'Опрос пройден';
        }
    }, [type]);


    const history = useHistory();

    const center = modal_type !== INSTRUCTION_MODAL ? {textAlign: 'center'}: {textAlign: 'left'}

    const access = useSelector((state) => state.access.access)

    useEffect(() => {
        let num = (task === 4 && test_count === 2) ? 2:test_count === 2 ? task -1: task
        if (task === 4 && test_count === 3) num = 3;
        if (num <= 0)
            num = 1
        setNumTest(num)
    }, [task])

    return(
        <div className={style.modal}>
            <div className={style.modal__content}>
                <div className={classNames(style.modal__title,{[style.instruction] : modal_type === INSTRUCTION_MODAL && type !== CONTINUE})}>
                    <p className={style.modal__title_title} style={center}>{info.title}</p>
                    {modal_type === INSTRUCTION_MODAL && type !== CONTINUE &&<p className={style.modal__title_text}>Задание №{num_test}</p>}
                </div>
                <div className={style.modalContent}>
                    {info.text}
                    {type !== EXIT && type !== RESEND_PASSWORD && type !== END &&
                        <button className={style.modalContent__button} onClick={() => {
                            if (modal.close) {
                                setModal({visible: false});
                            } else
                                getFunctionForModal(setModal, modal, modal_type, task, text, type, history,test_count,access);
                        }}>{modal.close ? 'Продолжить опрос' : info.button_text}</button>}
                    {type === EXIT &&
                        <div className={style.exitContainer}>
                            <button className={style.exitContainer__close} onClick={() => {
                                getFunctionForModal(setModal, modal, modal_type, 1, text, type, history,test_count,access);
                            }}>{modal.close ? 'Продолжить опрос' : info.button_text}</button>
                            <button className={style.exitContainer__save} onClick={() => {
                                getFunctionForModal(setModal, modal, modal_type, 2, text, type, history,test_count,access);
                            }}>{modal.close ? 'Продолжить опрос' : info.button_text_second}</button>
                        </div>
                    }
                    {type === RESEND_PASSWORD && 
                        <div className={style.exitContainer}>
                            <button className={style.exitContainer__close} onClick={() => {
                                getFunctionForModal(setModal, modal, modal_type, 1, text, type, history,test_count,access);
                            }}>{info.button_text}</button>
                            <button className={style.exitContainer__save} onClick={() => {
                                getFunctionForModal(setModal, modal, modal_type, 2, text, type, history,test_count,access);
                            }}>{info.button_text_second}</button>
                        </div>
                    }
                </div>
            </div>
            <div className={style.backGround}>

            </div>
        </div>
    )
}

export default Modal;
