import React, {useState} from "react";
import style from "./third_task.module.scss";
import {CloseIcon} from "../../Icons/Icons";
import LinearProgress from "@material-ui/core/LinearProgress";
import classNames from 'classnames';
import Checkbox from "../../../Elements/Checkbox";
import {EXIT, INFO_MODAL} from "../../../Modals/modalHelper";
import { useRef } from "react";

const ExcludePart = ({visible,progress,setModal, taskData,changeAnswer,excludeAnswers,getValue,...props}) =>  {

    const scrollRef = useRef();
    const [answerSent, setAnswerSent] = useState(false)

    const closeWindow = () => {
        setModal({
            visible: true,
            modal_type: INFO_MODAL,
            task: 1,
            type: EXIT,
            text: progress.start
        })
    }

    return(
        <main className={classNames(style.task, {
            [style.hide]: !visible
        })}>
            <div  className={`${style.task__main} ${answerSent && style.task__answered}`}>
                {/* <button  className={style.task__main_close} onClick={() => closeWindow()}>
                    {CloseIcon}
                </button> */}
                <LinearProgress variant="determinate" value={taskData ?
                    (progress.start / progress.end)*100: 0
                } />
                <div className={style.task__title} ref={scrollRef}>
                    <p className={style.task__title_title}>Исключите 5 НАИМЕНЕЕ значимых для вас факторов в работе</p>
                </div>
                <div 
                    className={style.task__tasks} 
                    style={{msGridRows: `${'1fr '.repeat(Math.ceil(Object.keys(taskData).length / 2))}`}}>
                    {taskData && Object.keys(taskData).map((key) => {
                        return(
                            <div className={style.taskField}>
                                <Checkbox onChange={()=>changeAnswer(key)} checked={getValue(key)} size={14}/>
                                <p className={style.taskField__text}>{taskData[key]}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <button className={style.task__buttons_next}
                    onClick={() => {
                        excludeAnswers();
                        scrollRef.current.scrollIntoView();
                        setAnswerSent(true);
                        setTimeout(() => {
                            setAnswerSent(false)
                        }, 500)
                    }}
            >
                Исключить
            </button>
        </main>
    )
}

export default ExcludePart;