let BACK_API_CHECK;
let HOST_CHECK;
console.log(window.location.origin)

if (!window.location.origin.includes('localhost')) {
  BACK_API_CHECK = window.location.origin + '/api';
  HOST_CHECK = window.location.origin + '/';
} else {
  BACK_API_CHECK = 'https://ectest.simonitor.com/api';
  HOST_CHECK = 'https://ectest.simonitor.com/';
}

export const BACK_API = BACK_API_CHECK;
export const HOST = HOST_CHECK;

export const USERS_AUTH = '/users/auth/';
export const TEST_RESULT = '/tests/result';
export const TEST_STATUS = '/tests/status';
export const TEST_ATTENTIVENESS = '/tests/attentiveness';
export const TEST_SINCERITY = '/tests/sincerity';
export const TESTS = '/tests/';
export const TEST_RESULT_FILE = '/tests/result/file';
export const TEST_STAGE = '/tests/stage/';
export const ADD_ADMIN = '/admin/add'
export const LOAD_USER_IMAGE = '/users/icon/upload'
export const ADD_USER = '/users/add/'
export const GET_USER_IMAGE = '/users/icon/';
export const GET_LIST_ADMIN = '/users/admin';
export const GET_LIST_USERS = '/users/users';
export const GET_COUNT_TEST = '/users/tests-number';
export const GET_USER_RESULT_FILE = '/tests/result/file/user';
export const GET_GREETING = '/users/greeting/';
export const GET_FAREWALL = '/users/farewell/';
export const TEST_RESULT_EXCLUSION = '/tests/result/stage/exclusion';
export const GET_USER_DATA = '/users/data';
export const TEST_RESULT_COMPARE = '/tests/result/stage/compare';
export const GET_PAIR = '/tests/pairs';
export const SEND_MAIL_PASSWORD = '/users/mail';


