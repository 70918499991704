import React, {useEffect, useRef, useState} from "react";
import style from "./second_task.module.scss";
import {CloseIcon} from "../../Icons/Icons";
import LinearProgress from "@material-ui/core/LinearProgress";
import Checkbox from "../../../Elements/Checkbox";
import {CONGRATULATIONS, CONTINUE, ERROR, EXIT, INFO_MODAL, INSTRUCTION_MODAL, TASK} from "../../../Modals/modalHelper";
import {getGroupByMotivator, sendRequest} from "../../../../helper";
import {TEST_ATTENTIVENESS, TEST_RESULT, TEST_SINCERITY, TEST_STAGE} from "../../../../helper/configurationConstants";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {setStatistic} from "../../../../store/actions/userActions";

const SecondTask = ({setTasks,setModal,countTests,results,returnToAuthorization,...props}) => {
    const [taskData, setTaskData] = useState({})
    const [progress, setProgress] = useState({start: 0});
    const access = useSelector((state) => state.access.access)
    const [answers, setAnswers] = useState({});
    const [copyTask, setCopyTask] = useState({});
    const [copyActualityStat,setCopyActualityStat] = useState({});
    const [nonActualArr, setNonActualArr] = useState([]);
    const [actualityStat, setActualityStat] = useState({});
    const dispatch = useDispatch();
    const farewell = useSelector((state) => state.user.farewell)
    const [loadedData, setLoadedData] = useState(false);
    const [countActual, setCountActual] = useState(false);
    const [answerSent, setAnswerSent] = useState(false);
    const scrollRef = useRef();

    const history = useHistory()

    const loadResults = (array,copy) => {
        sendRequest('GET',TEST_RESULT + '/stage/' + 2, undefined,access).then(result => {
            if (result && !result.statusCode) {
                setLoaded(result,array,copy)
            } else {
                returnToAuthorization()
            }
        })
    }


    const setLoaded = (results,array,copy) => {
        if (results){
            if (results.length === 19) {
                // setModal({
                //     visible: true,
                //     modal_type: INFO_MODAL,
                //     task: 2,
                //     type: CONGRATULATIONS,
                // })
                history.push('/tasks/third');
            }
            else {
                let answers_result = {}
                let stat_result = {}
                results.forEach((element, index) => {
                    if (!answers_result[index + 1])
                        answers_result[index + 1] = {}
                    element.result.forEach(item => {
                        answers_result[index + 1][item] = true;
                        if (index < 15) {
                            if (!stat_result[item])
                                stat_result[item] = 1;
                            else
                                stat_result[item] = stat_result[item] +1
                        }
                    })
                });
                setActualityStat(stat_result)
                setAnswers(answers_result)
                setProgress({...progress,start: results.length, end: 19})
                if (results.length > 14){
                    //setCoefStat(results.length, answers_result)
                    setManuallyTask(stat_result,array,copy)
                }
            }
        }
        setLoadedData(true)
    }

    useEffect(() => {
        const pageAccessedByReload = (
            (window.performance.navigation && window.performance.navigation.type === 1) ||
              window.performance
                .getEntriesByType('navigation')
                .map((nav) => nav.type)
                .includes('reload')
        );
        if (loadedData){
            if (Object.keys(answers).length === 0)
                setModal({visible:true,
                    modal_type: INSTRUCTION_MODAL,
                    task :2,
                })
            else if (!pageAccessedByReload)
                setModal({visible:true,
                    modal_type: INSTRUCTION_MODAL,
                    type: CONTINUE,
                    task :2,
                    close: Boolean(answers && Object.keys(answers).length > 0)
                })
        }
    }, [loadedData])

    useEffect(() => {
        if (countTests === 1){
            history.push('/tasks/first')
        }
    }, [countTests])

    useEffect(() => {
        window.scrollTo(0,0)
        setTasks(2)
        sendRequest('GET',TEST_STAGE + '2',undefined,access, setModal).then((res)=>{
            if (res){
                if (!res.statusCode){

                    let count = 0
                    let array = []
                    let obj = {}
                    Object.keys(res).forEach(key => {
                        count ++;
                        let values = []
                        obj[key] = {}
                        res[key].forEach(element => {
                            Object.keys(element).forEach(key_val => {
                                values.push({id: key_val, value: element[key_val]})
                                obj[key][key_val] = element[key_val]
                            })
                        })
                        array.push(values);
                    })
                    for(let i = 16; i < 20; i++){
                        res[i].forEach((item, index_res) => {
                            Object.keys(item).forEach(key => {
                                if (key == 1 || key == 2 || key ==3) {
                                    res[i][index_res][45 + Number(key)] = item[key]
                                    delete res[i][index_res][key]
                                }
                            })
                        })
                        if (res[i].length > 3) {
                            setCountActual(true);
                        }
                    }
                    setCopyTask(obj)
                    setTaskData({...taskData, tasks: array});
                    setProgress({...progress, end:count})
                    // if (results && results.loadResults && results.stage === 2)
                    loadResults(array,obj)
                }else
                    returnToAuthorization()
            }else
                returnToAuthorization()
        })
    },[])

    const changeAnswer = (id) => {
        let copy_answer = answers[progress.start+1] ? answers[progress.start+1] : {};
        let count = 0
        Object.keys(copy_answer).forEach(key => {
            if (copy_answer[key])
                count++;
        })
        if (count < 3 || (Object.keys(copy_answer).indexOf(id) !== -1 && count <= 3 && copy_answer[id])) {
            copy_answer[id] = !Boolean(copy_answer[id]);
            setAnswers({...answers, [progress.start + 1]: copy_answer})
        }
    }

    const getValue = (id) =>{
        let copy_answer = answers[progress.start+1] ? answers[progress.start+1] : {};
        return copy_answer[id]
    }

    const checkAndStep = () => {
        let copy_answer = answers[progress.start+1] ? answers[progress.start+1] : {};
        let count = 0
        if (progress.start < progress.end) {
            let data = {}
            data['num'] = progress.start + 1;
            let arr = []
            Object.keys(copy_answer).forEach(key => {
                if (copy_answer[key]) {
                    count++;
                    arr.push(key)
                }
            })
            data['result'] = arr;
            if (count === 3) {
                saveData(data)
                if (progress.start + 1 !== 19) {
                    let actStat = checkActuality();

                    if (progress.start + 1 === 15) {
                        setCopyActualityStat(actualityStat)
                    }
                    if (progress.start +1 > 15){
                        setCoefStat(progress.start + 1)
                    }
                    window.scrollTo(0,0)
                }else {
                    setCoefStat(progress.start + 1)
                    setModal({
                        visible: true,
                        modal_type: INFO_MODAL,
                        task: 2,
                        text: farewell,
                        type:CONGRATULATIONS});
                }
            }else {
                setModal({visible: true, modal_type: INFO_MODAL, task: 2, type:TASK});
            }
        }else {
            setModal({
                visible: true,
                modal_type: INFO_MODAL,
                task: 2,
                type: CONGRATULATIONS,
            })
        }
    }

    const setCoefStat = (end,ans) =>{
        let copy_stat = {}
        let copy_answers = ans ? ans: answers
        for (let i = 16; i <= end; i++)
            Object.keys(copy_answers[String(i)]).forEach(key_answer => {
                if (copy_answers[String(i)][key_answer]){
                    copy_stat[key_answer] = copy_stat[key_answer] ? copy_stat[key_answer] + 1: 1
                }
            })
        // setCopyActualityStat(copy_stat);
        if (end === 19){
            calculateValues(copy_stat)
        }
    }

    // const examp = {
    //     21: 3,
    //     37: 3,
    //     3: 2,
    //     9: 2,
    //     12: 2,
    //     27: 2,
    //     28: 2,
    //     34: 2,
    //     38: 2,
    //     41: 2,
    //     45: 2,
    //     5: 1,
    //     6: 1,
    //     14: 1,
    //     10: 1,
    //     11: 1,
    //     17: 1,
    //     18: 1,
    //     15: 1,
    //     19: 1,
    //     22: 1,
    //     24: 1,
    //     25: 1,
    //     26: 1,
    //     29: 1,
    //     30: 1,
    //     31: 1,
    //     32: 1,
    //     33: 1,
    //     36: 1,
    //     42: 1,
    //     44: 1
    // }

    const setManuallyTask = (actStat,tasks,copy,payload) => {
        let copy_stat = actStat ? actStat: actualityStat;
        let copy_task = copy ? copy : copyTask;
        let actual = [];
        let non_actual =[];
        let values = {}
        let maxAct = {max: 0, count: 0}
        Object.keys(copy_stat).forEach(key => {
            if (copy_stat[key] > 0 && copy_stat[key] < 3) {
                maxAct.max = Math.max(maxAct.max, copy_stat[key])
                maxAct.count++
            }
        })
        Object.keys(copy_stat).forEach(key =>{
            if (copy_stat[key] > 2){
                actual.push(key);
            }
        })
        if (actual.length < 3){
            Object.keys(copy_stat).forEach(key =>{
                if (copy_stat[key] > 0 && copy_stat[key] < 3 && actual.length < 3 && copy_stat[key] === maxAct.max ){
                    actual.push(key);
                }
            })
        }
        Object.keys(copy_stat).forEach(key =>{
            if (copy_stat[key] > 0 && copy_stat[key] < 3 && actual.indexOf(key) === -1){
                non_actual.push(key);
            }
        })

        if (non_actual.length < 6){
            Object.keys(copy_stat).forEach(key =>{
                if (copy_stat[key] > 2 && non_actual.indexOf(key) === -1 && actual.length < 6){
                    non_actual.push(key);
                }
            })
        }
        let actual_arr = [];
        let non_actual_arr = [];
        actual.forEach(el => {
            Object.keys(copy_task).forEach((key, index) => {
                if (index < 15){
                    Object.keys(copy_task[key]).forEach((key_item) => {
                        if (Number(key_item) == Number(el)){
                            actual_arr.push({id: key_item, value: copy_task[key][key_item]});
                            delete copy_task[key][key_item];
                        }
                    })
                }
            })
        });
        non_actual.forEach(el => {
            Object.keys(copy_task).forEach((key, index) => {
                if (index < 15){
                    Object.keys(copy_task[key]).forEach((key_item) => {
                        if (Number(key_item) == Number(el)){
                            non_actual_arr.push({id: key_item, value: copy_task[key][key_item]});
                            delete copy_task[key][key_item];
                        }
                    })
                }
            })
        })
        if (actual_arr.length < 12){
            non_actual.forEach(el => {
                if (actual_arr.length < 12) {
                    Object.keys(copy_task).forEach((key, index) => {
                        if (index < 15) {
                            Object.keys(copy_task[key]).forEach((key_item) => {
                                if (Number(key_item) == Number(el)) {
                                    actual_arr.push({id: key_item, value: copy_task[key][key_item]});
                                    delete copy_task[key][key_item];
                                }
                            })
                        }
                    })
                }
            })
        }
        if (non_actual.length < 24){
            actual.forEach(el => {
                if (non_actual_arr.length < 24) {
                    Object.keys(copy_task).forEach((key, index) => {
                        if (index < 15) {
                            Object.keys(copy_task[key]).every((key_item) => {
                                if (Number(key_item) == Number(el)) {
                                    non_actual_arr.push({id: key_item, value: copy_task[key][key_item]});
                                    delete copy_task[key][key_item];

                                }
                            })
                        }
                    })
                }
            })
        }
        let res = getUniqueValue(actual_arr, non_actual_arr)
        values.actual = res.act;
        values.non_actual = res.non_act;
        console.log(values)
        let task = [];
        let data = tasks ? tasks : taskData.tasks
        for(let i = 15; i < 19; i++){
            data[i].forEach((item, index_res) => {
                item.id = (Number(item.id) + 45).toString();
            })
        }
        const chosenStatements = [];
        
        for (let i = 0; i < 4; i++){
            let actualArr = [];
            let nonActualArr = [];
            Object.keys(values.actual).forEach(key => {
                actualArr.push(key);
            })
            Object.keys(values.non_actual).forEach(key => {
                nonActualArr.push(key);
            })
            if (Object.keys(values.actual).length < 3){
                Object.keys(values.non_actual).forEach(key => {
                    if (actualArr.length < 3 && actualArr.indexOf(String(key)) === -1 && nonActualArr.indexOf(String(key)) === -1){
                        actualArr.push(key);
                    }
                })
            }
            if (Object.keys(values.non_actual).length < 6){
                Object.keys(values.actual).forEach(key => {
                    if (nonActualArr.length < 6 && actualArr.indexOf(String(key)) === -1 && nonActualArr.indexOf(String(key)) === -1){
                        nonActualArr.push(key);
                    }
                })
            }
            // console.log(actualArr, nonActualArr)
            setNonActualArr(nonActualArr);
            let splicedActualArr;
            if (actualArr.length >= 6) {
                splicedActualArr = [...actualArr].slice(i % 2 === 0? 0: 3, i % 2 === 0? 3: 6);
            } else {
                splicedActualArr = [...actualArr].slice(i % 2 === 0? 0: actualArr.length -3, i % 2 === 0? 3: actualArr.length);
            }
            let splicedNonActualArr = [...nonActualArr].slice(i % 2 === 0? nonActualArr.length -6: 0, i % 2 === 0? nonActualArr.length: 6);
            let current_task = [];

            console.log(splicedActualArr, splicedNonActualArr,)

            let randomInts = [];
            while (randomInts.length < 3) {
                let r = Math.floor(Math.random() * 3);
                if (randomInts.indexOf(r) === -1) randomInts.push(r);
            }

            const fictiveMotivators = [];

            console.log(data[15+i])

            current_task.push(values.non_actual[splicedNonActualArr[getDecValue(0,i,5)]] ?
                values.non_actual[splicedNonActualArr[getDecValue(0,i,5)]][i] :
                values.actual[splicedNonActualArr[getDecValue(0,i,5)]][i]);
            chosenStatements.push(values.non_actual[splicedNonActualArr[getDecValue(0,i,5)]] ?
                values.non_actual[splicedNonActualArr[getDecValue(0,i,5)]][i] :
                values.actual[splicedNonActualArr[getDecValue(0,i,5)]][i])
            current_task.push(data[15+i][randomInts[0]]);
            current_task.push(values.non_actual[splicedNonActualArr[getDecValue(1,i,5)]] ?
                values.non_actual[splicedNonActualArr[getDecValue(1,i,5)]][i] :
                values.actual[splicedNonActualArr[getDecValue(1,i,5)]][i]);
            chosenStatements.push(values.non_actual[splicedNonActualArr[getDecValue(1,i,5)]] ?
                values.non_actual[splicedNonActualArr[getDecValue(1,i,5)]][i] :
                values.actual[splicedNonActualArr[getDecValue(1,i,5)]][i])
            if (payload) {
                current_task.push(payload[i][0]);
            } else if (data[15 + i].length > 3) {
                current_task.push(data[15 + i][3]);
            } else {
                if (values.actual[splicedActualArr[getDecValue(0,i,2)]]) {
                    current_task.push(values.actual[splicedActualArr[getDecValue(0,i,2)]][i]);
                } else if (values.non_actual[splicedActualArr[getDecValue(0,i,2)]]) {
                    current_task.push(values.non_actual[splicedActualArr[getDecValue(0,i,2)]][i]);
                    chosenStatements.push(values.non_actual[splicedActualArr[getDecValue(0,i,2)]][i]);
                } else {
                    const nonActualKeys = Object.keys(values.non_actual);
                    for (let j = 0; j < nonActualKeys.length; j++) {
                        if (!splicedNonActualArr.includes(nonActualKeys[j]) && !fictiveMotivators.includes(nonActualKeys[j])) {
                            const statement = values.non_actual[nonActualKeys[j]][i % 2 === 0? i : values.non_actual[nonActualKeys[j]].length - 1 - i];
                            if (!chosenStatements.includes(statement)) {
                                fictiveMotivators.push(nonActualKeys[j]);
                                current_task.push(statement);
                                chosenStatements.push(statement);
                                break;
                            }
                        }
                    }
                }
            }
            current_task.push(values.non_actual[splicedNonActualArr[getDecValue(2,i,5)]] ?
                values.non_actual[splicedNonActualArr[getDecValue(2,i,5)]][i] :
                values.actual[splicedNonActualArr[getDecValue(2,i,5)]][i]);
            chosenStatements.push(values.non_actual[splicedNonActualArr[getDecValue(2,i,5)]] ?
                values.non_actual[splicedNonActualArr[getDecValue(2,i,5)]][i] :
                values.actual[splicedNonActualArr[getDecValue(2,i,5)]][i]);
            current_task.push(data[15+i][randomInts[1]]);
            if (payload) {
                current_task.push(payload[i][1]);
            } else if (data[15 + i].length > 3) {
                current_task.push(data[15 + i][4]);
            } else {
                if (values.actual[splicedActualArr[getDecValue(1,i,2)]]) {
                    current_task.push(values.actual[splicedActualArr[getDecValue(1,i,2)]][i]);
                } else if (values.non_actual[splicedActualArr[getDecValue(1,i,2)]]) {
                    current_task.push(values.non_actual[splicedActualArr[getDecValue(1,i,2)]][i]);
                } else {
                    const nonActualKeys = Object.keys(values.non_actual);
                    for (let j = 0; j < nonActualKeys.length; j++) {
                        if (!splicedNonActualArr.includes(nonActualKeys[j]) && !fictiveMotivators.includes(nonActualKeys[j])) {
                            const statement = values.non_actual[nonActualKeys[j]][i % 2 === 0? i : values.non_actual[nonActualKeys[j]].length - 1 - i];
                            if (!chosenStatements.includes(statement)) {
                                fictiveMotivators.push(nonActualKeys[j]);
                                current_task.push(statement);
                                chosenStatements.push(statement);
                                break;
                            }
                        }
                    }
                }
            }
            current_task.push(values.non_actual[splicedNonActualArr[getDecValue(3,i,5)]] ?
                values.non_actual[splicedNonActualArr[getDecValue(3,i,5)]][i] :
                values.actual[splicedNonActualArr[getDecValue(3,i,5)]][i]);
            chosenStatements.push(values.non_actual[splicedNonActualArr[getDecValue(3,i,5)]] ?
                values.non_actual[splicedNonActualArr[getDecValue(3,i,5)]][i] :
                values.actual[splicedNonActualArr[getDecValue(3,i,5)]][i]);
            current_task.push(values.non_actual[splicedNonActualArr[getDecValue(4,i,5)]] ?
                values.non_actual[splicedNonActualArr[getDecValue(4,i,5)]][i] :
                values.actual[splicedNonActualArr[getDecValue(4,i,5)]][i]);
            chosenStatements.push(values.non_actual[splicedNonActualArr[getDecValue(4,i,5)]] ?
                values.non_actual[splicedNonActualArr[getDecValue(4,i,5)]][i] :
                values.actual[splicedNonActualArr[getDecValue(4,i,5)]][i]);
                if (payload) {
                    current_task.push(payload[i][2]);
                } else if (data[15 + i].length > 3) {
                    current_task.push(data[15 + i][5]);
                } else {
                    if (values.actual[splicedActualArr[getDecValue(2,i,2)]]) {
                        current_task.push(values.actual[splicedActualArr[getDecValue(2,i,2)]][i]);
                    } else if (values.non_actual[splicedActualArr[getDecValue(2,i,2)]]) {
                        current_task.push(values.non_actual[splicedActualArr[getDecValue(2,i,2)]][i]);
                    } else {
                        const nonActualKeys = Object.keys(values.non_actual);
                        for (let j = 0; j < nonActualKeys.length; j++) {
                            if (!splicedNonActualArr.includes(nonActualKeys[j]) && !fictiveMotivators.includes(nonActualKeys[j])) {
                                const statement = values.non_actual[nonActualKeys[j]][i % 2 === 0? i : values.non_actual[nonActualKeys[j]].length - 1 - i];
                                if (!chosenStatements.includes(statement)) {
                                    fictiveMotivators.push(nonActualKeys[j]);
                                    current_task.push(statement);
                                    chosenStatements.push(statement);
                                    break;
                                }
                            }
                        }
                    }
                }
            current_task.push(data[15+i][randomInts[2]]);
            current_task.push(values.non_actual[splicedNonActualArr[getDecValue(5,i,5)]] ?
                values.non_actual[splicedNonActualArr[getDecValue(5,i,5)]][i] :
                values.actual[splicedNonActualArr[getDecValue(5,i,5)]][i]);
            chosenStatements.push(values.non_actual[splicedNonActualArr[getDecValue(5,i,5)]] ?
                values.non_actual[splicedNonActualArr[getDecValue(5,i,5)]][i] :
                values.actual[splicedNonActualArr[getDecValue(5,i,5)]][i]);
            task.push(current_task)


            // current_task.push(values.non_actual[nonActualArr[0]] ? values.non_actual[nonActualArr[0]][i] : values.actual[nonActualArr[0]][i]);
            // current_task.push(data[15+i][0]);
            // current_task.push(values.non_actual[nonActualArr[1]] ? values.non_actual[nonActualArr[1]][i] : values.actual[nonActualArr[1]][i]);
            // current_task.push(values.actual[actualArr[0]] ? values.actual[actualArr[0]][i] : values.non_actual[actualArr[0]][i]);
            // current_task.push(values.non_actual[nonActualArr[2]] ? values.non_actual[nonActualArr[2]][i] : values.actual[nonActualArr[2]][i]);
            // current_task.push(data[15+i][1]);
            // current_task.push(values.actual[actualArr[1]] ? values.actual[actualArr[1]][i] : values.non_actual[actualArr[1]][i]);
            // current_task.push(values.non_actual[nonActualArr[3]] ? values.non_actual[nonActualArr[3]][i] : values.actual[nonActualArr[3]][i]);
            // current_task.push(values.non_actual[nonActualArr[4]] ? values.non_actual[nonActualArr[4]][i] : values.actual[nonActualArr[4]][i]);
            // current_task.push(values.actual[actualArr[2]] ? values.actual[actualArr[2]][i] : values.non_actual[actualArr[2]][i]);
            // current_task.push(data[15+i][2]);
            // current_task.push(values.non_actual[nonActualArr[5]] ? values.non_actual[nonActualArr[5]][i] : values.actual[nonActualArr[5]][i]);
            // task.push(current_task)

            // current_task.push(values.non_actual[nonActualArr[getDecValue(0,i,5)]][i]);
            // current_task.push(data[15+i][getDecValue(0,i,2)]);
            // current_task.push(values.non_actual[nonActualArr[getDecValue(1,i,5)]][i]);
            // current_task.push(values.actual[actualArr[getDecValue(0,i,2)]][i]);
            // current_task.push(values.non_actual[nonActualArr[getDecValue(2,i,5)]][i]);
            // current_task.push(data[15+i][getDecValue(1,i,2)]);
            // current_task.push(values.actual[actualArr[getDecValue(1,i,2)]][i]);
            // current_task.push(values.non_actual[nonActualArr[getDecValue(3,i,5)]][i]);
            // current_task.push(values.non_actual[nonActualArr[getDecValue(4,i,5)]][i]);
            // current_task.push(values.actual[actualArr[getDecValue(2,i,2)]][i]);
            // current_task.push(data[15+i][getDecValue(2,i,2)]);
            // current_task.push(values.non_actual[nonActualArr[getDecValue(5,i,5)]][i]);
            // task.push(current_task)
        }
        let copy_task_data =  tasks ? tasks : taskData.tasks;
        console.log(task)
        for (let i = 0; i < 4; i++) {
            copy_task_data[i + 15] = task[i]
        }
        setTaskData({...taskData, tasks: copy_task_data})
    }

    const getDecValue = (num, plus, max) => {
        let a = []
        for (let j = 0;j <= max;j++) a.push(j)
        for(let i = 0; i < plus; i++) a.unshift(a.pop());
        return a[num]
    }

    const saveData = (info) => {
        setAnswerSent(true);
        sendRequest("POST", TEST_RESULT + '/stage/2', JSON.stringify(info), access, setModal).then(r => {
            setAnswerSent(false);
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            if (isSafari) {
                scrollRef.current.scrollIntoView();
            }
            if (r.statusCode) {
                setModal({
                    visible: true,
                    modal_type: INFO_MODAL,
                    type: ERROR
                });
                window.alert = function(text) {
                    console.log('tried to alert ' + text)
                };
            } else {
                if (info.num === 15) {
                    let actStat = checkActuality();
                    if (r.payload) {
                        const properPayload = [];
                        r.payload.forEach((block, ind) => {
                            const newBlock = [];
                            block.forEach((statement) => {
                                const key = Object.keys(statement)[0];
                                newBlock.push({id: (Number(key) + 45).toString(), value: statement[key]});
                            })
                            properPayload.push(newBlock);
                        })
                        setManuallyTask(actStat, null, null, properPayload);
                        setCountActual(true);
                    } else {
                        setManuallyTask(actStat);
                        setCountActual(false);
                    }
                }
            }
            if (info.num !== 19) {
                setProgress({...progress, start: progress.start + 1})
            }
        })
    }


    const getUniqueValue = (act, notact) => {
        let res = {
            act: {},
            non_act: {}
        }
        act.forEach( element =>{
            if (res.act[element.id]){
                res.act[element.id].push(element);
            }else{
                res.act[element.id] = [element]
            }
        })
        notact.forEach( element =>{
            if (res.non_act[element.id]){
                res.non_act[element.id].push(element);
            }else{
                res.non_act[element.id] = [element]
            }
        })
        return res
    }

    const checkActuality = () => {
        let copy_stat = {}
        Object.keys(answers).forEach(key => {
            Object.keys(answers[key]).forEach(key_answer => {
                if (answers[key][key_answer]){
                    copy_stat[key_answer] = copy_stat[key_answer] ? copy_stat[key_answer] + 1: 1
                }
            })
        })
        setActualityStat(copy_stat);
        return copy_stat;
    }

    const calculateValues = (stat) =>{
        let copy_stat =  actualityStat;
        let values = {};
        let non_actual = [];
        let actual = []
        Object.keys(copy_stat).forEach(key =>{
            if (copy_stat[key] > 0 && copy_stat[key] < 3){
                non_actual.push(key);
            } else if (copy_stat[key] >= 3)
                actual.push(key)

        })
        let count_PV = 0;
        let count_PI = 0;
        Object.keys(stat).forEach(key => {
            if (nonActualArr.indexOf(String(key)) !== -1){
                count_PV += stat[key]
            }
            if (Number(key) > 45 && Number(key) <= 48){
                count_PI += stat[key];
            }
        })
        values.PV = (48 - (3.75 * count_PV))/48 >= 0 ? (48 - (3.75 * count_PV))/48 : 0;
        values.PI = (48 - (2 * 3.75 * count_PI))/48 >= 0 ? (48 - (2 * 3.75 * count_PI))/48 : 0;
        if (!countActual)
            values.PV = 'xxx'
        sendConstant(values)
        dispatch(setStatistic(values));
    }

    const sendConstant = (values) => {
        sendRequest('POST', TEST_ATTENTIVENESS, JSON.stringify({stage: 2, res: values.PV}),access).then(r => {})
        sendRequest('POST', TEST_SINCERITY, JSON.stringify({res: values.PI}),access).then(r => {})
    }

    const closeWindow = () => {
        setModal({
            visible: true,
            modal_type: INFO_MODAL,
            task: 1,
            type: EXIT,
            text: progress.start
        })
    }

    return(
        loadedData ?
            <main className={style.task}>
                <div  className={`${style.task__main} ${answerSent && style.task__answered}`}>
                    {/* <button  className={style.task__main_close} onClick={() => closeWindow()}>
                        {CloseIcon}
                    </button> */}
                    <LinearProgress variant="determinate" value={taskData.tasks ?
                        (progress.start / progress.end)*100: 0
                    } />
                    <div className={style.task__title} ref={scrollRef}>
                        <p className={style.task__title_title}>Сейчас я хочу</p>
                        <p className={style.task__title_description}>Блок №{progress.start +1}</p>
                    </div>
                    <div className={style.task__tasks}>
                        {taskData.tasks && taskData.tasks[progress.start].map((item) => {
                            return(
                                <div className={style.taskField}>
                                    <Checkbox onChange={()=>changeAnswer(item.id)} checked={getValue(item.id)} size={20}/>
                                    <p className={style.taskField__text}>{item.value}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div className={style.task__buttons}>
                        <button className={style.task__buttons_next}
                                onClick={() => checkAndStep()}>
                            Далее
                        </button>
                    </div>
                </div>
            </main>:
            <main/>
    )

}

export default SecondTask;
