import React, {useEffect, useState} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import {CloseIcon} from "../../Icons/Icons";
import style from './first_task.module.scss'
import classNames from 'classnames'
import {sendRequest} from "../../../../helper";
import {TEST_RESULT, TEST_STAGE, TEST_STATUS} from "../../../../helper/configurationConstants";
import {useSelector} from "react-redux";
import {CONGRATULATIONS, CONTINUE, ERROR, EXIT, INFO_MODAL, INSTRUCTION_MODAL, TASK} from "../../../Modals/modalHelper";
import {useHistory} from "react-router-dom";
import {FIRST_STAGE_SCALE} from "../tasksInfo";


const FirstTask = ({setTasks,setModal,results,returnToAuthorization,...props}) => {
    const [progress, setProgress] = useState({start: 0});
    const [activeStep, setActiveStep] = useState(-5)
    const [typeAnswers, setTypeAnswers] = useState({});
    const access = useSelector((state) => state.access.access)
    const [taskData, setTaskData] = useState({stage:1})
    const [stage, setStage] = useState(0);
    const [countAnswers, setCountAnswers] = useState(0);
    const [maxAnswers, setMaxAnswers] = useState(0);
    const scale = FIRST_STAGE_SCALE;
    const [mainResult, setMainResult] = useState();
    const farewell = useSelector((state) => state.user.farewell)
    const [loaded, setLoaded] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false)
    const count_test = useSelector((store) => store.user.count_tasks)
    const history = useHistory()


    const shuffle_array = (array,rand) => {
        return array.sort(() => Math.random() - rand);
    }

    const loadResults = (titles) => {
        sendRequest('GET',TEST_RESULT + '/stage/' + 1, undefined,access).then(result => {
            if (result && !result.statusCode) {
                setLoadedData(result,titles)
            }else {
                returnToAuthorization()
            }
        })
    }

    const setLoadedData = (results,titles) => {
        setMaxAnswers(Object.keys(titles[0]).length * FIRST_STAGE_SCALE.length);
        if (results && results[0]){
            let progress_num = 0;
            let scale_num = 0
            let copy_answers = {}
            let flag = false;
            let used_elements = []
            let copy_titles = titles;
            let count_on_stage = []
            let count_answers = 0
            results.forEach(element => {
                count_answers += element.result.length;
            });
            setCountAnswers(count_answers);
            if (count_answers < Object.keys(titles[0]).length * FIRST_STAGE_SCALE.length){
                results.forEach(element => {
                    if (element.result.length > 0) {
                        element.result.forEach((item, index) => {
                            if (!count_on_stage[index])
                                count_on_stage[index] = 0
                            count_on_stage[index] = count_on_stage[index] + 1;
                            if (!copy_answers[index])
                                copy_answers[index] = [];
                            copy_answers[index].push({
                                title: element.text, 
                                value: item,
                                num: Number(element.num)
                            })
                        })
                    }
                    progress_num += element.result.length
                    if (element.result.length > scale_num)
                        scale_num = element.result.length
                })
                results.forEach(element => {
                    if (element.result.length === scale_num) {
                        used_elements.push({id: String(element.num), value: element.text})
                    }
                }) 
                let array = [];
                let identifier = scale_num - 1 >= 0 ? scale_num - 1 : 0
                if (count_on_stage[identifier] === Object.keys(titles[0]).length)
                    flag = true
                if (identifier === FIRST_STAGE_SCALE.length )
                    identifier --;
                Object.keys(copy_titles[identifier]).forEach(key => {
                    if (!(used_elements.filter(el => el.id === copy_titles[identifier][key].id).length > 0))
                        array.push(copy_titles[identifier][key])
                })
                let res_element = {}
                used_elements.forEach((item, index) => {
                    res_element[index] = item;
                })
                array.forEach((item, index) => {
                    res_element[index + used_elements.length] = item;
                })
                copy_titles[identifier] = res_element;
                setTaskData({
                    ...taskData,
                    stage: used_elements.length + 1 > Object.keys(titles[0]).length ? 1 : used_elements.length + 1,
                    titles: copy_titles
                })
                setStage(flag ? scale_num : scale_num - 1 >= 0 ? scale_num - 1 : 0)
                setProgress({...progress, start: progress_num, end: Object.keys(titles[0]).length})
                setTypeAnswers(copy_answers)
            }
        }
        setLoaded(true)
    }

    useEffect(() => {
        const pageAccessedByReload = (
            (window.performance.navigation && window.performance.navigation.type === 1) ||
              window.performance
                .getEntriesByType('navigation')
                .map((nav) => nav.type)
                .includes('reload')
        );
       if (loaded && !(count_test === 2 || countAnswers >= maxAnswers)){
           if (Object.keys(typeAnswers).length === 0)
               setModal({visible:true,
                   modal_type: INSTRUCTION_MODAL,
                   task :1,
               })
           else if (!pageAccessedByReload) {
               setModal({visible:true,
                   modal_type: INSTRUCTION_MODAL,
                   type: CONTINUE,
                   task :1,
                   close: Boolean(typeAnswers && Object.keys(typeAnswers).length > 0)
               })
           }
       }
    }, [loaded])

    useEffect(() => {
        if (loaded && (count_test === 2 || countAnswers >= maxAnswers)) {
            history.push('/tasks/second')
        }
    }, [count_test, loaded])

    useEffect(() => {
        setTasks(1)
        window.scrollTo(0,0)
        sendRequest('GET',TEST_STAGE + '1',undefined,access, setModal).then((res)=>{
            if (res && !res.statusCode){
                let array = [];
                let result = []
                setMainResult(res)
                Object.keys(res).forEach(key => {
                    array.push({id: key, value: res[key]})
                })
                for (let i = 0; i < FIRST_STAGE_SCALE.length; i++){
                    let res_arr = shuffle_array(array, Math.random())
                    let result_object = {}
                    for(let j = 0; j < res_arr.length; j++) {
                        result_object[j] = res_arr[j];
                    }
                    result.push(result_object)
                }
                setTaskData({...taskData, titles: result});
                setProgress({...progress, end:Object.keys(res).length})
                loadResults(result)
            }else {
                returnToAuthorization()
            }
        })
    },[])

    useEffect(() => {
        if (results && results.loadResults && results.stage === 2)
            loadResults()
    }, [results])

    const setResult = (result) => {
        if (!isSubmit){
            setIsSubmit(true)
            setTimeout(() => {
                let copy_answer = typeAnswers;
                let flag = true;
                let index_item = -1;
                if ( !copy_answer[stage])
                    copy_answer[stage] = []
                copy_answer[stage].forEach((el, index) => {
                    if (el.num === Number(result.num)){
                        flag = false;
                        index_item = index;
                    }
                })
                if (flag) {
                    copy_answer[stage].push(result)
                    setTypeAnswers(copy_answer)
                    setProgress({...progress, start: progress.start + 1})
                }else {
                    copy_answer[stage][index_item] = result;
                    setTypeAnswers(copy_answer);
                }
                let saveData ={
                    "stage" : 1,
                    "num" : Number(result.num),
                    "scaleNum" : stage + 1,
                    "value" : result.value
                }
                if (!flag) {
                    saveData["isTrueChange"] = true;
                }
                if (taskData.stage === progress.end) {
                    saveData["finishedScale"] = true;
                }
                sendRequest("POST", TEST_RESULT, JSON.stringify(saveData), access, setModal).then(r => {
                    if (count_test === 1 && taskData.stage === progress.end && stage >= scale.length - 1) {
                        sendRequest('POST', TEST_STATUS, JSON.stringify({finished: true}), access).then(r => {
                        })
                    }
                    if (r.statusCode) {
                        setModal({
                            visible: true,
                            modal_type: INFO_MODAL,
                            type: ERROR
                        });
                        window.alert = function(text) {
                            console.log('tried to alert ' + text)
                        };
                    }
                    setIsSubmit(false)
                })
                if (taskData.stage === progress.end) {
                    if (stage < scale.length - 1) {
                        let copy_titles = taskData.titles;
                        // copy_titles[stage + 1] = shuffle_array(copy_titles[stage + 1])
                        setStage(stage + 1);
                        setTaskData({...taskData, stage: 1, titles: copy_titles});
                        setModal({
                            visible: true,
                            modal_type: INFO_MODAL,
                            task: 1,
                            type: TASK,
                            text: scale[stage + 1].first + ' - ' + scale[stage + 1].second
                        })
                    } else {
                        setModal({
                            visible: true,
                            modal_type: INFO_MODAL,
                            text: farewell,
                            task: 1,
                            type: CONGRATULATIONS,
                        })
                    }
                } else {
                    if (Object.keys(copy_answer[stage]).length >= taskData.stage) {
                          setTaskData({...taskData, stage: Object.keys(copy_answer[stage]).length + 1})
                        }
                    else {
                        setTaskData({...taskData, stage: taskData.stage + 1})
                    }
                }
                setActiveStep(undefined);
            }, 300)
        }
    }

    const editAnswer = (num) => {
        let copy_answer = typeAnswers;
        let step = taskData.stage
        copy_answer[stage].forEach((el, index) => {
            if ( el.num === num){
                step = index + 1;
            }
        })
        setTaskData({...taskData, stage: step});
    }

    const closeWindow = () => {
        setModal({
            visible: true,
            modal_type: INFO_MODAL,
            task: 1,
            type: EXIT,
            text: progress.start
        })
    }

    return(
        loaded ?
                <main className={style.firstTask}>
                    <div  className={style.firstTask__main}>
                        {/* <button  className={style.firstTask__main_close} onClick={() => closeWindow()}>
                            {CloseIcon}
                        </button> */}
                        <LinearProgress variant="determinate" value={taskData.titles ?
                            (progress.start / (progress.end * scale.length))*100: 0
                        } />
                        <p className={style.firstTask__main_title}>{(taskData.titles && taskData.titles[stage] && taskData.titles[stage][taskData.stage-1])
                        && taskData.titles[stage][taskData.stage-1].value}</p>
                        <div className={style.selector__typeMobile}><p>{scale[stage].first}</p>  <p>{scale[stage].second}</p></div>
                        <div className={style.selector}>
                            <p className={style.selector__type}>{scale[stage].first}</p>
                            {[-3,-2,-1,0,1,2,3].map(item => {
                                return(
                                    <button className={style.selector__button} onClick={() => {
                                        setActiveStep(item)
                                        setResult({
                                            title: taskData.titles[stage][taskData.stage - 1].value,
                                            num: Number(taskData.titles[stage][taskData.stage - 1].id),
                                            value: item
                                        })
                                    }}>
                                        <div className={classNames(style.selector__button_value,
                                            {[style.activeSelect] : item === activeStep})}>
                                            {Math.abs(item)}
                                        </div>
                                    </button>
                                )
                            })}
                            <p className={style.selector__type}>{scale[stage].second}</p>
                        </div>
                    </div>
                    <div className={style.firstTask__answers}>
                        <div className={style.answerTitle}>
                            <p className={style.answerTitle__text}>Уже оценили</p>
                            <p className={style.answerTitle__description}>По шкале {scale[stage].first} - {scale[stage].second}</p>
                        </div>
                        <div className={style.answerTypes}>
                            {typeAnswers[stage] && typeAnswers[stage].map(type => (
                                <button className={style.answerTypes__tag}
                                        onClick={()=>{
                                            editAnswer(type.num)
                                        }}
                                >{type.title}</button>
                            ))}
                        </div>
                    </div>
                </main>:
                <main/>
    )
}


export default FirstTask;
