import { combineReducers } from 'redux'
import {userReducer} from "./userReducer";
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';
import {persistReducer} from 'redux-persist';
import {accessReducer} from "./tokenStorage/accessStore";
import {refreshReducer} from "./tokenStorage/refreshStore";


const accessPersistConfig = {
    key: 'access',
    storage: new CookieStorage(Cookies, {
        expiration: {
            'default': 20 * 86400 // Cookies expire after one year
        }
    }),
    whitelist: ['access']
}

const refreshPersistConfig = {
    key: 'refresh',
    storage: new CookieStorage(Cookies, {
        expiration: {
            'default': 9 * 86400 // Cookies expire after one year
        }
    }),
    whitelist: ['refresh']
}


export const rootReducer = combineReducers({
    access:persistReducer(accessPersistConfig,accessReducer),
    refresh:persistReducer(refreshPersistConfig,refreshReducer),
    user: userReducer
});
