const SET_ACCESS = "access/SET_ACCESS";

export const setAccess = (access) => ({ type: SET_ACCESS, access });


export const accessReducer = (state = {access:""}, action) => {
  switch (action.type) {
    case SET_ACCESS:{
      return {access:action.access};
    }
    default:
      return state;
  }
};