const SET_REFRESH = "refresh/SET_REFRESH";

export const setRefresh = (refresh) => ({ type: SET_REFRESH, refresh });


export const refreshReducer = (state = {refresh:""}, action) => {
  switch (action.type) {
    case SET_REFRESH:{
      return {refresh:action.refresh};
    }
    default:
      return state;
  }
};