import { CONNECTION_ERROR, INFO_MODAL } from "../Components/Modals/modalHelper";
import {BACK_API} from "./configurationConstants";

export function getFetchInit(method, headers, body){
    let init =  {
        method: method,
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        mode: 'cors',
    }
    if (body)
        init.body = body;
    return init;
}

export const sendRequest = async (method, end_point, data, token, setModal) => {
    let Headers = {
        "Content-Type": "application/json"
    }
    if (token)
        Headers["Authorization"] =  "JWT " + token;
    let res = await fetch( BACK_API + end_point,  getFetchInit(method, Headers,  data)).then(async (response) => {
        let result = await response.json()
        return await result
    }).catch(err => {
        if (setModal) {
            setModal({
                visible: true,
                modal_type: INFO_MODAL,
                type: CONNECTION_ERROR
            });
        }
        return 400
    })
    return res;
}

export const sendUserImage = async(endpoint, file, token,id) => {
    let Headers = {
        "Authorization": "JWT " + token
    }
    let dataFile = new FormData();
    dataFile.append('user',id);
    dataFile.append('name',file.name);
    dataFile.append('file', file,file.name);
    let res = await fetch( BACK_API + endpoint,  getFetchInit('POST',Headers,  dataFile)).then(async (response) => {
        let result = await response.json()
        return await result
    }).catch(err => {
    })
    return res;
}

export const sendUserData = async(endpoint, file,info, token) => {
    let Headers = {
        "Authorization": "JWT " + token
    }
    let dataFile = new FormData();
    dataFile.append('email',info.email);
    dataFile.append('password',info.password);
    dataFile.append('file', file,file.name);
    let res = await fetch( BACK_API + endpoint,  getFetchInit('POST',Headers,  dataFile)).then(async (response) => {
        let result = await response.json()
        return await result
    }).catch(err => {
    })
    return res;
}

export const getGroupByMotivator = (index) => {
    if (index > 0 && index < 5)
        return 1;
    else if (index > 4 && index < 7)
        return 2;
    else if (index > 6 && index < 15)
        return 3;
    else if (index > 14 && index < 20)
        return 4;
    else if (index > 19 && index < 23)
        return 5;
    else if (index > 22 && index < 27)
        return 6;
    else if (index > 26 && index < 31)
        return 7;
    else if (index > 30 && index < 33)
        return 8;
    else if (index > 32 && index < 37)
        return 9;
    else if (index > 36 && index < 43)
        return 10;
    else if (index > 42 && index < 46)
        return 11;
    else
        return index;
}

const users = [
    {
        "email": "xc895@mail.ru",
        "password" : "password2"
    },
    {
        "email": "denisgeyer@yandex.ru",
        "password" : "password"
    },{
        "email": "geier@sixhands.co",
        "password" : "password2"
    }
]
